import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CustomersApi } from "../../apiclient";
import { CustomerListItemViewModel } from "../../apiclient/models";
import _ from "lodash";
import CustomerGiftCardListComponent from "./CustomerGiftCardListComponent";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const CustomerDetailPage = () => {
  let { id } = useParams();
  let customerApi = new CustomersApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [loading, setLoading] = useState(true as boolean);
  const authContext = useContext(AuthContext);
  //const dayjs = require("dayjs");
  const [model, setModel] = useState({} as CustomerListItemViewModel);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    customerApi
      .customersGetByIdIdGet(id!)
      .then((response) => {
        var data = response.data;
        setModel(data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
       <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
            <div className="container-fluid py-4">
              <div className="row align-items-center">
                <div className="col">
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <Link
                        className="bi bi-chevron-left text-xs"
                        to={`/customers`}
                      ></Link>
                    </div>
                    <div className="vr opacity-20 my-1"></div>
                    <div className="col ps-4 pt-4"><h6 className="text-xs text-uppercase text-muted mb-1">{model.customerName}</h6><h1 className="h2">{model.customerEmail}</h1><div className="d-flex gap-2 flex-wrap mt-3 d-none d-sm-block"><a href="#" className="bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs">UI/UX</a> <a href="#" className="bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs">Mobile Apps</a> <a href="#" className="bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs">UI Research</a></div></div>

                  </div>
                </div>
                <div className="col-auto">
                  <div className="hstack gap-2 justify-content-end">
                    {/* <a
                      href="https://help.wrappedgiftcard.com/en/collections/3830625-account#general"
                      target={"_blank"}
                      className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block"
                    >
                      <i className="bi bi-question-circle-fill"></i>
                      <span className="d-none d-sm-inline ms-2">
                        Need help?
                      </span>
                    </a> */}
                    <button type="submit" className="btn btn-sm btn-primary">
                      <span>Issue and activate</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
      <div className="flex-lg-1 h-screen overflow-y-lg-auto">
        <div className="py-6 bg-surface-secondary">
          <div className="p-5">
            <div className="row g-6">
              <div className="col-xl-9">
                <div className="vstack gap-2">
                  <div className="row g-6">
                    <div className="col-xl-4 col-sm-6 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                               Num gift cards issued
                              </span>{" "}
                              <span className="h3 font-bold mb-0">{model.numGiftCards}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                <i className="bi bi-gift"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                Gift card(s) balance
                              </span>{" "}
                              <span className="h3 font-bold mb-0">
                                $140.23 NZD
                              </span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-info text-white text-lg rounded-circle">
                                <i className="bi bi-credit-card"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                Lifetime value
                              </span>{" "}
                              <span className="h3 font-bold mb-0">
                                700.82 NZD
                              </span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                <i className="bi bi-people"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {model && model.customerEmail &&
                  <div className="mt-5"><CustomerGiftCardListComponent customerEmail={model.customerEmail} /></div>
}
                </div>
              </div>
              <div className="col-xl-3">
                <div className="vstack gap-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <h5 className="mb-4">Activity</h5>
                        <div className="ms-auto text-end">
                          {/* <a className="text-sm font-semibold cursor-pointer">
                            Edit
                          </a> */}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="bi bi-envelope-fill me-2 text-muted"></i>{" "}
                        <a
                          href="#"
                          className="text-sm text-heading text-primary-hover"
                        >
                          thomasbrad1@gmail.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerDetailPage;
