import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Table,
  DropdownButton,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CampaignApi } from "../../apiclient";
import {
  CampaignListItem,
  Campaign
} from "../../apiclient/models";
import _ from "lodash";
import AnalyticsPanelComponent from "../analytics/AnalyticsPanelComponent";
import GiftCardCsvUploadModalComponent from "../giftcards/GiftCardCsvUploadModalComponent";
import GridTable from "../../components/GridTable";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
import * as Swal from "sweetalert2";
import ProPlanUpsellBanner from "../../components/ProPlanUpsellBanner";

const CampaignListPage = () => {
  let navigate = useNavigate();

  let campaignApi = new CampaignApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [loading, setLoading] = useState(true as boolean);
  const authContext = useContext(AuthContext);
  //const dayjs = require("dayjs");
  const [model, setModel] = useState([] as CampaignListItem[]);
  const [createButtonText, setCreateButtonText] = useState("Create campaign" as string);

  useEffect(() => {
    refreshList();
  }, []);

  const createNewCampaign = () => {
    setCreateButtonText("Creating...");
    campaignApi.campaignCreateCampaignPost()
      .then((response) => {
        var data = response.data as Campaign;
        navigate(`/campaigns/edit/${data.id}`);
        setCreateButtonText("Creating campaign");
      }).catch((error) => {
        toast.error("Failed to create campaign. You need to be on our Pro, Plus, or Enterprise plan to access this feature");
        setCreateButtonText("Creating campaign");
      });
  }

  const refreshList = () => {
    setLoading(true)
    campaignApi.campaignListCampaignsGet().then((response) => {
      var data = response.data as CampaignListItem[];
      setModel(data);
      setLoading(false);
    });
  }

  const cloneCampaign = (campaign: CampaignListItem) => {
    campaignApi.campaignCloneCampaignByIdIdGet(campaign.campaignId!)
      .then((response) => {
        var data = response.data;
        if (data) {
          toast.success("Campaign cloned.");
          refreshList();
        } else {
          toast.error("Couldn't clone campaign.");
        }
      })
      .catch((error: any) => {
        toast.error("Couldn't clone campaign.");
      });
  };

  const deleteCampaign = (campaign: CampaignListItem) => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `This will delete the campaign. Note: this will not delete any generated gift cards or credits.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete campaign",

        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          campaignApi.campaignDeleteCampaignIdDelete(campaign.campaignId!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Campaign deleted.");
                refreshList();
              } else {
                toast.error("Couldn't delete campaign.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't delete campaign.");
            });
        }
      });
  };

  const activateCampaign = (campaign: CampaignListItem) => {
    Swal.default
      .fire({
        title: "Activate campaign?",
        text: `This will activate the campaign`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, activate campaign",
      })
      .then((result) => {
        if (result.isConfirmed) {
          campaignApi.campaignEnableCampaignIdPut(campaign.campaignId!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Campaign activated.");
                refreshList();
              } else {
                toast.error("Couldn't activate campaign.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't activate campaign.");
            });
        }
      });
  };

  const disableCampaign = (campaign: CampaignListItem) => {
    Swal.default
      .fire({
        title: "Set campaign back to draft?",
        text: `This will set the campaign status back to draft. Note: this will not delete any generated gift cards or credits.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, set status to draft",

        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          campaignApi.campaignSetStatusToDraftIdPut(campaign.campaignId!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Campaign set to draft.");
                refreshList();
              } else {
                toast.error("Couldn't set campaign back to draft.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't set campaign back to draft.");
            });
        }
      });
  };

  const getStatusBadge = (campaign: CampaignListItem) => {
    var cardStatus = campaign.campaignStatus;
    var badgeColor = "bg-secondary text-dark";
    if (cardStatus !== "Disabled" && cardStatus !== "Deleted" && cardStatus !== "Draft") {
      badgeColor = "bg-success text-success";
    }
    return (
      <span
        className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
      >
        <OverlayTrigger
          overlay={<Tooltip id={campaign.campaignId}> {dayjs(campaign.scheduledAt)
            .utc()
            .tz(authContext?.account?.ianaTimezone)
            .format("YYYY-MM-DD hh:mm a")}</Tooltip>}
          placement="top"
        >
          <span
          >
            {cardStatus} <span className={`bi bi-info-circle`}></span>
          </span>
        </OverlayTrigger>
      </span>
    )
  }

  return (
    <React.Fragment>
      <ProPlanUpsellBanner />
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  {(authContext.account?.userRole == "Admin" ||
                    authContext.account?.userRole == "Owner") && (
                      <>
                        <button
                          type="button"
                          className="float-end ms-2 btn btn-primary btn-sm"
                          onClick={createNewCampaign}
                        >
                          {createButtonText}
                        </button>

                      </>
                    )}
                  <h1 className="h2 ls-tight">Campaigns</h1>
                </div>
                <p className="text-sm pb-5">
                  Create and manage gift card campaigns <span className="badge text-uppercase text-dark bg-secondary">beta</span>
                </p>
              </div>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid vstack gap-3">
            <div className="bg-light">
              <div className="col-sm-12 col-12">
                {loading && <Spinner />}
                {model && !loading && model.length == 0 &&
                  <>
                   
                   <div className="p-10 row g-10 g-lg-20 justify-content-around align-items-center">
                      <div className="col-lg-6">
                        <h1 className="ls-tight font-bolder mb-5">
                        Drive sales with Campaigns
                        </h1>
                        <p className="lead lh-loose text-muted mb-7">
                        Use Campaigns to drive sales around launches, events and promotions. Replace marketing discounts with gift card campaigns for a higher perceived value and better return.
                        </p>
                        <ul className="list-unstyled">
                          <li className="py-3">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="icon icon-xs text-sm icon-shape bg-opacity-30 bg-success text-success rounded-circle me-3">
                                  <i className="bi bi-check"></i>
                                </div>
                              </div>
                              <div>
                                <span className="text-heading font-semibold mb-0">
                                  Target a select list of customers
                                </span>
                              </div>
                            </div>
                          </li>
                          <li className="py-3">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="icon icon-xs text-sm icon-shape bg-opacity-30 bg-success text-success rounded-circle me-3">
                                  <i className="bi bi-check"></i>
                                </div>
                              </div>
                              <div>
                                <span className="text-heading font-semibold mb-0">
                                  Bulk send gift card codes with current balances
                                </span>
                              </div>
                            </div>
                          </li>
                          
                          <li className="py-3">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="icon icon-xs text-sm icon-shape bg-opacity-30 bg-success text-success rounded-circle me-3">
                                  <i className="bi bi-check"></i>
                                </div>
                              </div>
                              <div>
                                <span className="text-heading font-semibold mb-0">
                                  Measure revenue generated
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <button
                          type="button"
                          className="ms-2 btn btn-primary"
                          onClick={createNewCampaign}
                        >
                          Create your first campaign
                        </button>
                        <a href="https://help.wrappedgiftcards.com/en/articles/10089822-setting-up-a-marketing-campaign" target="_blank" className="mx-5">Learn more</a>
                      </div>
                      <div className="col-lg-6">
                        <div className="svg-fluid">
                          <img src="https://s3.us-east-1.amazonaws.com/giftkard.uploads/campaign-hero-image.png" />
                        </div>
                      </div>
                    </div>

                  </>}
                {model && model.length > 0 &&
                  <>

                    <Table striped bordered hover className="tb-campaigns">
                      <thead className="table-light">
                        <tr>
                          <th>Campaign Name</th>
                          <th>Batch</th>
                          <th>Credit Redeemed / Issued</th>
                          <th>Recipients</th>
                          <th>Revenue</th>
                          <th>Created at</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {model &&
                          model.map((batch: CampaignListItem, i: number) => (
                            <>
                              <tr key={`row-batch-${batch.campaignId}`}>
                                <td>
                                  <Link
                                    className="text-link active"
                                    to={`/campaigns/edit/${batch.campaignId}`}
                                  >
                                    {batch.campaignName}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    className="text-link active"
                                    to={`/gift-cards?batchId=${batch.giftCardBatchId}`}
                                  >
                                    {batch.giftCardBatchName}
                                  </Link>
                                </td>
                                <td>
                                  {batch.creditRedeemedFormatted} /{" "}
                                  {batch.creditIssuedFormatted}
                                </td>
                                <td>{batch.numGiftCards}</td>
                                <td><Link to={`/reporting/revenue/campaign/${batch.campaignId}`} className="text-link">{batch.revenueGeneratedFormatted}</Link></td>
                                <td>
                                  <span>
                                    {dayjs(batch.createdAt)
                                      .utc()
                                      .tz(authContext?.account?.ianaTimezone)
                                      .format("YYYY-MM-DD hh:mm a")}
                                  </span>
                                </td>
                                <td>{getStatusBadge(batch)}</td>
                                <td>
                                  <DropdownButton
                                    key={`actions-menu-${batch.campaignId}`}
                                    id="`actions-menu-${batch.campaignId}`"
                                    title="Actions"
                                    variant="neutral"
                                    size="sm"
                                  >
                                    <Dropdown.Item onClick={() => navigate(`/campaigns/edit/${batch.campaignId}`)}>
                                      Edit
                                    </Dropdown.Item>

                                    {batch.campaignStatus == "Scheduled" ?
                                      <>
                                        <Dropdown.Item onClick={() => disableCampaign(batch)}>
                                          Set to draft
                                        </Dropdown.Item>
                                      </> : <>
                                        {batch.campaignStatus === "Draft" &&
                                          <Dropdown.Item onClick={() => activateCampaign(batch)}>
                                            Activate
                                          </Dropdown.Item>}
                                      </>}
                                    <Dropdown.Item onClick={() => cloneCampaign(batch)}>
                                      Clone
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => deleteCampaign(batch)}>
                                      <span className="text-danger">Delete</span>
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </>
                }
              </div>
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default CampaignListPage;
