import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { GiftcardApi, StorefrontApi } from "../../apiclient";
import {
  SalesChannelListItemViewModel,
  GiftCardProductViewModel,
  GiftCardProductVariant,
  KSeriesSettingPostViewModel,
  GiftCardExpiryViewModel,
  StorefrontSettingsViewModel,
  StorefrontFee,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Modal,
  InputGroup,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";

import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import Util from "../../js/util";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditStorefrontDomainModal from "./EditStorefrontDomainModal";
import StorefrontToggleEnabledBanner from "./StorefrontToggleEnabledBanner";
import SettingsNavMenu from "../../components/SettingsNavMenu";
import { CopyBlock, dracula } from "react-code-blocks";
import { useNavigate } from "react-router";

const StorefrontSettingsPage = () => {
  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as StorefrontSettingsViewModel);
  const [validated, setValidated] = useState(false);
  const [expiryValidated, setExpiryValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);
  const [searchParams, setSearchParams] = useSearchParams();
  const [imageData, setImageData] = useState(null as any);
  const [description, setDescription] = useState("");
  const [stripeConnected, setStripeConnected] = useState(false as boolean);
  const [isEditSubdomainModalShown, setIsEditSubdomainModalShown] =
    useState(false);
  const [showEmbedCodeModal, setShowEmbedCodeModal] = useState(false as boolean);
  const [embedCodeBlockHtml, setEmbedCodeBlockHtml] = useState("" as string);
  const navigate = useNavigate();

  const copyToClipboard = (textToCopy: string, successMessage: string) => {
    navigator.clipboard.writeText(textToCopy);
    toast.success(successMessage);
  };

  const copyEmbedCodeToClipboard = () => {
    copyToClipboard(embedCodeBlockHtml, 'Copied!');
    setShowEmbedCodeModal(false);
  }

  useEffect(() => {
    fetchSettings();

    // const redirectType = searchParams.get("redirectType") ?? "";
    // if (redirectType && redirectType === "return") {
    //   toast.error("Return");
    // }
  }, []);

  const fetchSettings = () => {
    setLoading(true);
    storefrontApi
      .storefrontSettingsGet(authContext.account?.accountId)
      .then((response) => {
        var data = response.data;
        setModel(data);
        setDescription(data.description!);
        setStripeConnected(data.stripeConnectDetailsSubmitted!);
        checkStripeIsConnected(data);
        setEmbedCodeBlockHtml(`<iframe src="${data.wrappedStorefrontSubdomain}.wrappedgiftcards.store" 
          allow="top-navigation" frameborder="0" width="100%" height="2100px" id="wrapped-storefront-iframe" name="wrapped-storefront-iframe" 
          sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms allow-popups allow-pointer-lock allow-downloads" 
          style="position: relative !important; visibility: visible !important; display: block !important; float: none !important; min-height: inherit !important; max-height: inherit !important; height: 1700px !important; width: 100% !important;">
          </iframe>`);

      })
      .catch((error: any) => {
        console.log("get error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkStripeIsConnected = (data: StorefrontSettingsViewModel) => {
    if (
      data &&
      data.stripeConnectAccountId &&
      !data.stripeConnectDetailsSubmitted
    ) {
      storefrontApi
        .storefrontConnectAccountDetailsSubmittedGet()
        .then((response) => {
          setStripeConnected(response.data);
          toast.success("Stripe account connected.");
        })
        .catch((error: any) => {
          console.log("get error", error);
        });
    }
  };

  const connectStripe = () => {
    storefrontApi
      .storefrontCreateConnectLinkGet()
      .then((response) => {
        var data = response.data;
        if (data) {
          window.location.href = data;
        }
      })
      .catch((error: any) => {
        console.log("get error", error);
      })
      .finally(() => { });
  };

  const handleChange = (event: any) => {
    const target = event.target;
    console.log(
      event.target.type,
      event.target.name,
      event.currentTarget.value
    );
    var value =
      target.type === "checkbox" ||
        target.type === "radio" ||
        target.type === "switch"
        ? target.checked
        : target.value;
    const name = event.target.name;
    console.log("handleChange", name, value);
    setUnsavedChanges(true);
    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  let handleVariantChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newVariants = [...model.variants!] as any;
    newVariants[i][name] = value;

    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      variants: newVariants,
    }));
  };

  let handleVariantPriceChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newVariants = [...model.variants!] as any;
    newVariants[i][name] = value;
    newVariants[i]["giftCardValue"] = value;

    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      variants: newVariants,
    }));
  };

  let handleVariantGiftCardValueChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newVariants = [...model.variants!] as any;
    newVariants[i]["giftCardValue"] = value;

    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      variants: newVariants,
    }));
  };

  let addVariant = () => {
    let newVariants = [...model.variants!] as GiftCardProductVariant[];
    newVariants.push({
      id: Util.generateRandomString(16),
      name: "",
      price: 0.0,
    });
    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      variants: newVariants,
    }));
  };

  let deleteVariant = (i: number) => {
    let newVariants = [...model.variants!] as any;
    newVariants.splice(i, 1);
    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      variants: newVariants,
    }));
  };

  //coupon

  let handleCouponChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newCoupons = [...model.coupons!] as any;
    newCoupons[i][name] = value;

    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      coupons: newCoupons,
    }));
  };

  let handleCouponPriceChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newCoupons = [...model.coupons!] as any;
    newCoupons[i][name] = value;
    newCoupons[i]["amount"] = value;

    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      coupons: newCoupons,
    }));
  };

  let addCoupon = () => {
    let newCoupons = [...model.coupons!] as StorefrontFee[];
    newCoupons.push({
      id: Util.generateRandomString(16),
      name: "",
      amount: 0.0,
    });
    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      coupons: newCoupons,
    }));
  };

  let deleteCoupon = (i: number) => {
    let newCoupons = [...model.coupons!] as any;
    newCoupons.splice(i, 1);
    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      coupons: newCoupons,
    }));
  };

  //fees
  let handleFeeChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newFees = [...model.fees!] as any;
    newFees[i][name] = value;

    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      fees: newFees,
    }));
  };

  let handleFeePriceChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newFees = [...model.fees!] as any;
    newFees[i][name] = value;
    newFees[i]["amount"] = value;

    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      fees: newFees,
    }));
  };

  let addFee = () => {
    let newFees = [...model.fees!] as StorefrontFee[];
    newFees.push({
      id: Util.generateRandomString(16),
      name: "",
      amount: 0.0,
    });
    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      fees: newFees,
    }));
  };

  let deleteFee = (i: number) => {
    let newFees = [...model.fees!] as any;
    newFees.splice(i, 1);
    setModel((values: StorefrontSettingsViewModel) => ({
      ...values,
      fees: newFees,
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      const formData = new FormData();
      formData.append("image", imageData);
      formData.append("variants", JSON.stringify(model.variants));
      console.log("variants", model.variants);
      storefrontApi
        .storefrontSaveSettingsPost(
          "1",
          model.headerImageUrl,
          description,
          imageData,
          JSON.stringify(model.variants),
          model.hideCheckBalance,
          model.hideWrappedBranding,
          model.shouldChargeFees,
          model.shouldAllowTopups,
          model.shouldAllowCoupons,
          JSON.stringify(model.fees),
          JSON.stringify(model.coupons)
        )
        .then((response: any) => {
          toast.success("Settings saved");
          setUnsavedChanges(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving settings.");
          setUnsavedChanges(false);
        });
    }
  };

  const onFileInputChange = (event: any) => {
    let file = event.target.files[0];
    let extension = file.name.split(".").at(-1);
    let allowedFileExtensions = ["jpg", "png", "jpeg", "gif", "bmp"];
    if (!allowedFileExtensions.includes(extension.toLowerCase())) {
      toast.error("Incorrect file format, failed to import.");
      // clear the file input
      event.target.value = null;
      return;
    }

    var reader = new FileReader();
    reader.onload = (e) => {
      if (e.target !== null) {
        var image = new Image();
        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result as string;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = image.height;
          var width = image.width;
          // if (height > 150 || width > 600) {
          //   toast.error(
          //     "File size is too large, maximum dimensions are 600x150"
          //   );
          //   // clear the file input
          //   event.target.value = null;
          //   setImageData(null);
          //   setModel({ ...model, imageUrl: undefined });
          //   return false;
          // }
          setModel({ ...model, headerImageUrl: image.src });
        };
      }
    };
    reader.readAsDataURL(event.currentTarget.files[0]);
    setImageData(event.currentTarget.files[0]);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <Spinner animation="border" />
        </>
      ) : (
        <>
          <header>
            <div className="container">
              <div className="border-bottom pt-6">
                <div className="row align-items-center">
                  <div className="col-sm-6 col-12">
                    <h1 className="h2 ls-tight">Storefront settings</h1>
                    <p className="text-sm pb-5">
                    Create your own storefront to sell gift cards on your website without an eCommerce integration
                    </p>
                  </div>
          
                  <div className="col-sm-6 col-12">
                    {/* <a
                  href="https://help.wrappedgiftcard.com/en/collections/3830625-account#general"
                  target={"_blank"}
                  className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block float-end"
                >
                  <i className="bi bi-question-circle-fill"></i>
                  <span className="d-none d-sm-inline ms-2">Help articles</span>
                </a> */}
                  </div>
                </div>
                <SettingsNavMenu />
              </div>
            </div>
          </header>

          <div className="bg-surface-secondary">
            <div className="container py-10">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row mb-10">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">Step 1: Connect a new Stripe account</h4>
                    <p className="text-sm">
                      We use Stripe's globally-trusted payment infrastucture to
                      power payments for your storefront. We recommend you connect a <strong>new</strong> Stripe account to Wrapped.
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card mb-10">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="list-group list-group-flush">
                            <div className="list-group-item d-flex align-items-center">
                              <div className="me-4">
                                <div className="icon icon-lg icon-shape text-xl bg-opacity-30 bg-neutral border">
                                  <img
                                    src="/img/integrations/stripe.png"
                                    alt="stripe"
                                  />
                                </div>
                              </div>
                              <div className="flex-fill">
                                <span className="d-block h6 text-sm font-semibold mb-1">
                                  Stripe
                                </span>
                                <span className="d-block text-xs text-muted">
                                  Accept payments from customers and receive
                                  payouts directly to your preferred bank
                                  account.
                                </span>
                              </div>
                              <div className="ms-auto">
                                {!stripeConnected ? (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary px-3 py-2 d-none d-md-inline-block"
                                    onClick={() => connectStripe()}
                                  >
                                    Connect
                                  </button>
                                ) : (
                                  <div className="d-flex align-items-center text-success text-sm font-semibold">
                                    <i className="bi bi-check-circle-fill me-1"></i>
                                    <span>Connected</span>
                                    <a
                                      className="ml-10 btn btn-sm btn-neutral px-3 py-2 d-none d-md-inline-block"
                                      href="https://dashboard.stripe.com/payments"
                                      target="_blank"
                                    >
                                      View payments
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {model && model.stripeConnectDetailsSubmitted && (
                  <div className="row mb-10">
                    <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                      <h4 className="font-semibold mb-2">Step 2: Design your storefront</h4>
                      <p className="text-sm">
                        These settings will be used for your online storefront.
                      </p>
                    </div>
                    <div className="col-lg-8">
                      <div className="card mb-10">
                        <StorefrontToggleEnabledBanner />

                        <div className="card-header d-flex align-items-center">
                          <h5>
                            Storefront url:{" "}
                            <a
                              href={`https://${model.wrappedStorefrontSubdomain}.wrappedgiftcards.store`}
                              target="_blank"
                            >
                              https://{model.wrappedStorefrontSubdomain}
                              .wrappedgiftcards.store
                            </a>
                          </h5>
                          <div className="ms-auto">
                            <a
                              href={`https://${model.wrappedStorefrontSubdomain}.wrappedgiftcards.store`}
                              target="_blank"
                              className="btn btn-sm btn-neutral ms-auto"
                            >
                              View <i className="bi bi-box-arrow-up-right"></i>
                            </a>
                            <button
                              type="button"
                              onClick={() => setIsEditSubdomainModalShown(true)}
                              className="btn btn-sm btn-neutral ms-2"
                            >
                              <i className="bi bi-pencil"></i> Edit
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-neutral ms-2"
                              onClick={() => setShowEmbedCodeModal(true)}
                            >
                              <i className="bi bi-code"></i> Get embed code
                            </button>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row g-5">
                            <div className="col-12">
                              <div className="">
                                <label
                                  className="form-label"
                                  htmlFor="description"
                                >
                                  Header description
                                </label>
                                <ReactQuill
                                  value={description}
                                  onChange={setDescription}
                                />
                              </div>
                            </div>
                            <div className="row mt-5">
                              <label className="form-label">Header image</label>
                              <div className="col-md-6">
                                <img
                                  src={model.headerImageUrl}
                                  className="img-thumbnail"
                                  alt="..."
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="card shadow-none border-2 border-dashed border-primary-hover position-relative">
                                  <div className="d-flex justify-content-center px-5 py-5">
                                    <label
                                      htmlFor="file_upload"
                                      className="position-absolute w-full h-full top-0 start-0 cursor-pointer"
                                    >
                                      {/* <input
                                    id="file_upload"
                                    name="file_upload"
                                    type="file"
                                    className="visually-hidden"
                                  /> */}

                                      <Form.Control
                                        id="file_upload"
                                        name="file_upload"
                                        onChange={onFileInputChange}
                                        type="file"
                                        className="visually-hidden"
                                        accept="image/jpg, image/png, image/jpeg, image/gif, image/bmp"
                                      />
                                    </label>
                                    <div className="text-center">
                                      <div className="text-2xl text-muted">
                                        <i className="bi bi-upload"></i>
                                      </div>
                                      <div className="d-flex text-sm mt-3">
                                        <p className="font-semibold">
                                          Upload a file or drag and drop
                                        </p>
                                      </div>
                                      <p className="text-xs text-gray-500">
                                        PNG, JPG, GIF up to 3MB
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                            <div className="mb-1">
                                <p className="alert alert-secondary">
                                  Note: Your storefront branding inherits the styling of your &quot;gift to a friend page&quot;.<br />You can further <a onClick={()=>navigate(`/assets/gift-to-friend-page`)} className="text-link cursor-pointer" target="_blank">customize your branding here</a>
                                </p>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="mb-1">
                                <label className="form-label">
                                  Gift card variants
                                </label>
                              </div>
                              <div className="row g-5 mb-3">
                                <div className="col-md-4">
                                  <p className="text-sm text-muted">
                                    Variant name
                                  </p>
                                </div>
                                <div className="col-md-3">
                                  <p className="text-sm text-muted">
                                    Gift card price
                                  </p>
                                </div>
                                <div className="col-md-3">
                                  <p className="text-sm text-muted">
                                    Gift card value
                                  </p>
                                </div>
                                <div className="col-md-2"></div>
                              </div>
                              {model &&
                                model.variants?.map((variant, i) => (
                                  <div
                                    key={"variant" + i}
                                    className="row g-5 pb-5"
                                  >
                                    <div className="col-md-4">
                                      <div className="">
                                        <Form.Control
                                          name={"name"}
                                          className="form-control"
                                          type="text"
                                          value={variant.name}
                                          onChange={(e) =>
                                            handleVariantChange(e, i)
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="">
                                        <div className="input-group input-group-inline">
                                          <span
                                            className="input-group-text"
                                            id=""
                                          >
                                            $
                                          </span>
                                          <Form.Control
                                            name={"price"}
                                            className="form-control"
                                            type="number"
                                            value={variant.price}
                                            onChange={(e) =>
                                              handleVariantPriceChange(e, i)
                                            }
                                            placeholder="0.00"
                                            aria-label="0.00"
                                            required
                                          />
                                          <span
                                            className="input-group-text"
                                            id=""
                                          >
                                            {
                                              authContext.account
                                                ?.isoCurrencyCode
                                            }
                                          </span>
                                        </div>
                                        <span className="mt-2 valid-feedback">
                                          Looks good!
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="">
                                        <div className="input-group input-group-inline">
                                          <span
                                            className="input-group-text"
                                            id=""
                                          >
                                            $
                                          </span>
                                          <Form.Control
                                            name={"giftCardValue"}
                                            className="form-control"
                                            type="number"
                                            value={variant.giftCardValue}
                                            onChange={(e) =>
                                              handleVariantGiftCardValueChange(e, i)
                                            }
                                            placeholder="0.00"
                                            aria-label="0.00"
                                            required
                                          />
                                          <span
                                            className="input-group-text"
                                            id=""
                                          >
                                            {
                                              authContext.account
                                                ?.isoCurrencyCode
                                            }
                                          </span>
                                        </div>
                                        <span className="mt-2 valid-feedback">
                                          Looks good!
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <button
                                        onClick={(e) => deleteVariant(i)}
                                        type="button"
                                        className="btn btn-neutral"
                                      >
                                        <i className="bi bi-trash"></i>
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              <div className="row g-5">
                                <div className="col-md-4">
                                  <button
                                    type="button"
                                    onClick={addVariant}
                                    className="btn btn-neutral btn-sm"
                                  >
                                    <i className="bi bi-plus pr-5"></i>Add
                                    different variant
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="d-flex mt-3">
                                <div className="me-3">
                                  <Form.Check
                                    name="hideCheckBalance"
                                    id="hideCheckBalance"
                                    type={"checkbox"}
                                    checked={model.hideCheckBalance}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="hideCheckBalance"
                                  >
                                    Hide check balance section
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="d-flex mt-3">
                                <div className="me-3">
                                  <Form.Check
                                    name="shouldAllowTopups"
                                    id="shouldAllowTopups"
                                    type={"checkbox"}
                                    checked={model.shouldAllowTopups}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="shouldAllowTopups"
                                  >
                                    Allow gift card top-ups
                                  </label>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-12">
                              <div className="d-flex mt-3">
                                <div className="me-3">
                                  <Form.Check
                                    name="hideWrappedBranding"
                                    id="hideWrappedBranding"
                                    type={"checkbox"}
                                    checked={model.hideWrappedBranding}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="hideWrappedBranding"
                                  >
                                    Hide Wrapped branding footer
                                  </label>
                                </div>
                              </div>
                            </div> */}
                            <div className="col-12">
                              <div className="d-flex mt-3">
                                <div className="me-3">
                                  <Form.Check
                                    name="shouldChargeFees"
                                    id="shouldChargeFees"
                                    type={"checkbox"}
                                    checked={model.shouldChargeFees}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="shouldChargeFees"
                                  >
                                    Charge additional fees?
                                  </label>
                                </div>
                              </div>
                            </div>
                            {model.shouldChargeFees && (
                              <div className="col-12">
                                <div className="mb-1">
                                  <label className="form-label">Fees</label>
                                </div>
                                <div className="row g-5 mb-3">
                                  <div className="col-md-3">
                                    <p className="text-sm text-muted">
                                      Fee type
                                    </p>
                                  </div>
                                  <div className="col-md-4">
                                    <p className="text-sm text-muted">
                                      Fee description
                                    </p>
                                  </div>
                                  <div className="col-md-3">
                                    <p className="text-sm text-muted">Amount</p>
                                  </div>
                                  <div className="col-md-2"></div>
                                </div>
                                {model &&
                                  model.fees?.map((fee, i) => (
                                    <div
                                      key={"fee" + i}
                                      className="row g-5 pb-5"
                                    >
                                      <div className="col-md-3">
                                        <div className="">
                                          <Form.Select
                                            name={"feeType"}
                                            onChange={(e) =>
                                              handleFeeChange(e, i)
                                            }
                                            value={fee.feeType ?? "percent"}
                                          >
                                            <option value="Percent">
                                              Percent
                                            </option>
                                            <option value="Dollar">
                                              Dollar
                                            </option>
                                          </Form.Select>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="">
                                          <Form.Control
                                            name={"name"}
                                            className="form-control"
                                            type="text"
                                            value={fee.name}
                                            onChange={(e) =>
                                              handleFeeChange(e, i)
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="">
                                          <div className="input-group input-group-inline">
                                            {fee.feeType === "Dollar" && (
                                              <span
                                                className="input-group-text"
                                                id=""
                                              >
                                                $
                                              </span>
                                            )}
                                            <Form.Control
                                              name={"amount"}
                                              className="form-control"
                                              type="number"
                                              value={fee.amount}
                                              onChange={(e) =>
                                                handleFeePriceChange(e, i)
                                              }
                                              placeholder="0.00"
                                              aria-label="0.00"
                                              required
                                            />
                                            {fee.feeType === "Dollar" ? (
                                              <span
                                                className="input-group-text"
                                                id=""
                                              >
                                                {
                                                  authContext.account
                                                    ?.isoCurrencyCode
                                                }
                                              </span>
                                            ) : (
                                              <span
                                                className="input-group-text"
                                                id=""
                                              >
                                                %
                                              </span>
                                            )}
                                          </div>
                                          <span className="mt-2 valid-feedback">
                                            Looks good!
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <button
                                          onClick={(e) => deleteFee(i)}
                                          type="button"
                                          className="btn btn-neutral"
                                        >
                                          <i className="bi bi-trash"></i>
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                <div className="row g-5">
                                  <div className="col-md-4">
                                    <button
                                      type="button"
                                      onClick={addFee}
                                      className="btn btn-neutral btn-sm"
                                    >
                                      <i className="bi bi-plus pr-5"></i>Add
                                      additional fee
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

<div className="col-12">
                              <div className="d-flex mt-3">
                                <div className="me-3">
                                  <Form.Check
                                    name="shouldAllowCoupons"
                                    id="shouldAllowCoupons"
                                    type={"checkbox"}
                                    checked={model.shouldAllowCoupons}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="shouldAllowCoupons"
                                  >
                                    Allow promotional code?
                                  </label>
                                </div>
                              </div>
                            </div>
                            {model.shouldAllowCoupons && (
                              <div className="col-12">
                                <div className="mb-1">
                                  <label className="form-label">Coupons</label>
                                </div>
                                <div className="row g-5 mb-3">
                                  <div className="col-md-3">
                                    <p className="text-sm text-muted">
                                      Coupon type
                                    </p>
                                  </div>
                                  <div className="col-md-4">
                                    <p className="text-sm text-muted">
                                      Code
                                    </p>
                                  </div>
                                  <div className="col-md-3">
                                    <p className="text-sm text-muted">Amount</p>
                                  </div>
                                  <div className="col-md-2"></div>
                                </div>
                                {model &&
                                  model.coupons?.map((coupon, i) => (
                                    <div
                                      key={"coupon" + i}
                                      className="row g-5 pb-5"
                                    >
                                      <div className="col-md-3">
                                        <div className="">
                                          <Form.Select
                                            name={"couponType"}
                                            onChange={(e) =>
                                              handleCouponChange(e, i)
                                            }
                                            value={coupon.couponType ?? "percent"}
                                          >
                                            <option value="Percent">
                                              Percent
                                            </option>
                                            <option value="Dollar">
                                              Dollar
                                            </option>
                                          </Form.Select>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="">
                                          <Form.Control
                                            name={"code"}
                                            className="form-control"
                                            type="text"
                                            value={coupon.code}
                                            onChange={(e) =>
                                              handleCouponChange(e, i)
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="">
                                          <div className="input-group input-group-inline">
                                            {coupon.couponType === "Dollar" && (
                                              <span
                                                className="input-group-text"
                                                id=""
                                              >
                                                $
                                              </span>
                                            )}
                                            <Form.Control
                                              name={"amount"}
                                              className="form-control"
                                              type="number"
                                              value={coupon.amount}
                                              onChange={(e) =>
                                                handleCouponPriceChange(e, i)
                                              }
                                              placeholder="0.00"
                                              aria-label="0.00"
                                              required
                                            />
                                            {coupon.couponType === "Dollar" ? (
                                              <span
                                                className="input-group-text"
                                                id=""
                                              >
                                                {
                                                  authContext.account
                                                    ?.isoCurrencyCode
                                                }
                                              </span>
                                            ) : (
                                              <span
                                                className="input-group-text"
                                                id=""
                                              >
                                                %
                                              </span>
                                            )}
                                          </div>
                                          <span className="mt-2 valid-feedback">
                                            Looks good!
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <button
                                          onClick={(e) => deleteCoupon(i)}
                                          type="button"
                                          className="btn btn-neutral"
                                        >
                                          <i className="bi bi-trash"></i>
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                <div className="row g-5">
                                  <div className="col-md-4">
                                    <button
                                      type="button"
                                      onClick={addCoupon}
                                      className="btn btn-neutral btn-sm"
                                    >
                                      <i className="bi bi-plus pr-5"></i>Add
                                      additional coupon
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="card-footer text-end py-4">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </>
      )}
      {model && model.wrappedStorefrontSubdomain && (
        <EditStorefrontDomainModal
          show={isEditSubdomainModalShown}
          onHide={() => setIsEditSubdomainModalShown(false)}
          subdomain={model.wrappedStorefrontSubdomain}
          updated={fetchSettings}
        />
      )}
      {model && embedCodeBlockHtml && (
        <Modal
          show={showEmbedCodeModal}
          onHide={() => setShowEmbedCodeModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Get embed code for storefront widget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <p className="mb-3">
                Copy the HTML block below and embed on your website:
              </p>
              <CopyBlock
                language={"html"}
                text={embedCodeBlockHtml}
                showLineNumbers={true}
                theme={dracula}
                wrapLongLines={false}
                onCopy={copyEmbedCodeToClipboard}
                codeBlock

              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={copyEmbedCodeToClipboard}>Copy</button>
            <button className="btn btn-secondary" onClick={() => setShowEmbedCodeModal(false)}>Cancel</button>
          </Modal.Footer>
        </Modal>

      )}
    </React.Fragment>
  );
};

export default StorefrontSettingsPage;
