import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi, GiftCardBatchApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import "bootstrap-daterangepicker/daterangepicker.css";
import { GiftcardApi } from "../../apiclient";
import {
  GiftCardBatchViewModel,
  GiftCardListItemViewModel,
  GiftCardReasonType,
} from "../../apiclient/models";
import _ from "lodash";
import GridTable from "../../components/GridTable";
import LaunchBanner from "../../components/LaunchBanner";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const CustomerGiftCardListComponent = (props : any) => {
  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  let giftCardBatchApi = new GiftCardBatchApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true as boolean);
  const [showMoreFilters, setShowMoreFilters] = useState(false as boolean);
  const authContext = useContext(AuthContext);
  //const dayjs = require("dayjs");
  const [data, setData] = useState([] as GiftCardListItemViewModel[]);
  const [batchList, setBatchList] = useState([] as GiftCardBatchViewModel[]);
  const [pageCount, setPageCount] = React.useState(0);
  const [refreshCount, setRefreshCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setfilterStatus] = useState("");
  const [filterBatchId, setfilterBatchId] = useState("");
  const [filterOrderId, setfilterOrderId] = useState("");
  const [filterIssuedDateFrom, setfilterIssuedDateFrom] = useState("");
  const [filterRecipientEmail, setfilterRecipientEmail] = useState(props.customerEmail);
  const [showUploadGiftCardsCsvModal, setShowUploadGiftCardsCsvModal] =
    useState(false as boolean);
  const navigate = useNavigate();

  const tableColumns = [
    {
      Header: "Code Ending",
      accessor: "orderDisplayName",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        return (
          <React.Fragment>
            <div>
              <Link
                className="nav-link font-semibold row"
                to={`/gift-cards/${item.id}`}
              >
                <div className="d-flex align-items-center col">
                  <i className="bi bi-credit-card me-2 text-lg"></i>
                  {item.code}
                </div>
                {item.notes && (
                  <div className="col align-items-center text-end text-sm pt-1">
                    <OverlayTrigger
                      key={"tooltip" + item.id}
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-top`}>{item.notes}</Tooltip>
                      }
                    >
                      <i className="bi bi-card-text"></i>
                    </OverlayTrigger>
                  </div>
                )}
              </Link>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        var badgeColor = "bg-success text-success";
        var cardStatus = "Redeemable";
        if (item.status === "Disabled") {
          cardStatus = "Disabled";
          badgeColor = "bg-danger text-danger";
        } else if (item.totalIssuedAmount == 0) {
          cardStatus = "Empty";
          badgeColor = "bg-secondary text-secondary";
        } else if (item.balance === 0) {
          cardStatus = "Redeemed";
          badgeColor = "bg-secondary text-secondary";
        } else if (item.balance === item.initialValue) {
          cardStatus = "Full";
        } else if (item.balance !== item.initialValue && item.balance! > 0) {
          cardStatus = "Partial";
        }
        return (
          <React.Fragment>
            <span
              className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
            >
              {cardStatus}
            </span>

            {item.hasSyncErrors && (
              <>
                <br />
                <Link to={`/gift-cards/${item.id}`}>
                  <span className="badge text-uppercase bg-danger">
                    {" "}
                    <i className="bi bi-exclamation-circle"></i> Sync error
                  </span>
                </Link>
              </>
            )}
          </React.Fragment>
        );
      },
    },
    {
      Header: "Date Issued",
      accessor: "dateIssued",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        return (
          <React.Fragment>
            <span>
              {dayjs(item.dateIssued)
                .utc()
                .tz(authContext?.account?.ianaTimezone)
                .format("YYYY-MM-DD hh:mm a")}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Remaining / Value",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        return (
          <React.Fragment>
            <span className="font-semibold text-heading">
              {item.formattedBalance} / {item.formattedTotalIssuedAmount}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Revenue",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        return (
          <React.Fragment>
            <Link className="font-semibold text-heading text-link" to={`/reporting/revenue/giftcard/${item.id}`}>
              {item.revenueGeneratedFormatted}
            </Link>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Reason",
      accessor: "reasonType",
      Cell: ({ value, row }: any) => {
        var item = row.original as GiftCardListItemViewModel;
        var badgeColor = "bg-dark text-dark";
        var cardReason = item.reasonType?.toString();
        if (item.reasonType === GiftCardReasonType.Refund) {
          badgeColor = "bg-danger text-danger";
        } else if (item.reasonType === GiftCardReasonType.GeneratedFromBatch) {
          cardReason = "Batch (Generated)";
        } else if (
          item.reasonType === GiftCardReasonType.ConvertedInstoreOnly
        ) {
          cardReason = "Instore only";
        } else if (
          item.reasonType === GiftCardReasonType.ImportedFromBatchCsv
        ) {
          cardReason = "Batch (CSV)";
        } else if (item.reasonType === GiftCardReasonType.CsvImport) {
          cardReason = "Import (CSV)";
        }
        return (
          <React.Fragment>
            <span
              className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
            >
              {cardReason}
            </span>
            {item.isPromotional &&
            <><br /><span className={`badge text-uppercase bg-opacity-30 bg-primary text-primary mt-2`}>Promo</span></>}
          </React.Fragment>
        );
      },
    },
  ];

  const findBatchById = (idToFind: string) => {
    return batchList.find((obj) => obj.giftCardBatchId === idToFind);
  };

  useEffect(() => {
    setfilterBatchId(searchParams.get("batchId") ?? "");
    giftCardBatchApi.giftCardBatchGiftCardBatchListGet().then((response) => {
      var data = response.data as GiftCardBatchViewModel[];
      setBatchList(data);
    });
  }, []);

  useEffect(() => {
    setRefreshCount(refreshCount + 1);
  }, [filterStatus, filterBatchId]);

  const fetchData = ({ pageSize, pageIndex, search, sortBy, status }: any) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      var isDesc = sortBy[0] ? sortBy[0].desc ?? "true" : "true";
      var sortField = sortBy[0] ? sortBy[0].id ?? "createdAt" : "createdAt";

      fetchAPIData({
        limit: pageSize,
        pageIndex: pageIndex,
        skip: pageSize * pageIndex,
        search: search,
        sortField: sortField,
        isDesc: isDesc,
        status,
      });
    }
  };

  const fetchAPIData = async ({
    limit,
    pageIndex,
    skip,
    search,
    sortField,
    isDesc,
    status,
  }: any) => {
    try {
      setLoading(true);
      if (!sortField) {
        sortField = "createdAt";
      }
      const serverSortField = sortField[0].toUpperCase() + sortField.slice(1); //capitalize first letter to match server field name
      console.log("fetchAPIData", filterBatchId);
      giftcardApi
        .giftcardListGet(
          filterStatus,
          pageIndex,
          skip,
          limit,
          serverSortField,
          isDesc,
          searchTerm,
          "",
          filterBatchId,
          filterRecipientEmail,
          "",
          filterOrderId,
          filterIssuedDateFrom
        )
        .then((response) => {
          const list = response.data.result;
          var pageSize = limit;
          var TotalCount = response.data.totalCount ?? 0;
          var pageCount = ((TotalCount + pageSize - 1) / pageSize) | 0;

          setData(list ?? ([] as GiftCardListItemViewModel[]));
          setPageCount(pageCount);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  const _handleSearch = _.debounce(
    (search: string) => {
      setSearchTerm(search);
    },
    1500,
    {
      maxWait: 1500,
    }
  );

  const handleBatchChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setfilterBatchId(value);
  };

  const handleRecipientEmailChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfilterRecipientEmail(value);
  };

  const handleIssuedDateFromChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfilterIssuedDateFrom(value);
  };

  const handleOrderIdChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setfilterOrderId(value);
  };

  const handleSearchSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setRefreshCount(refreshCount + 1);
    console.log("search submit");
  };

  return (
    <React.Fragment>
     <div className="card">
              <div className="col-sm-12 col-12">
                <ul className="nav nav-pills py-3 pl-20">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        filterStatus === "" ? "active" : ""
                      }`}
                      onClick={() => setfilterStatus("")}
                    >
                      All
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        filterStatus === "redeemable" ? "active" : ""
                      }`}
                      onClick={() => setfilterStatus("redeemable")}
                    >
                      Redeemable
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        filterStatus === "full" ? "active" : ""
                      }`}
                      onClick={() => setfilterStatus("full")}
                    >
                      Full
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        filterStatus === "partial" ? "active" : ""
                      }`}
                      onClick={() => setfilterStatus("partial")}
                    >
                      Partial
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        filterStatus === "redeemed" ? "active" : ""
                      }`}
                      onClick={() => setfilterStatus("redeemed")}
                    >
                      Redeemed
                    </button>
                  </li>

                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        filterStatus === "disabled" ? "active" : ""
                      }`}
                      onClick={() => setfilterStatus("disabled")}
                    >
                      Disabled
                    </button>
                  </li>
                </ul>
              </div>
              <div className="p-5 pt-0">
                <GridTable
                  columns={tableColumns}
                  data={data}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  search={searchTerm}
                  goToFirstPage={undefined}
                  status={refreshCount} //hack to support other filters
                />
              </div>
              <div></div>
            </div>
    </React.Fragment>
  );
};

export default CustomerGiftCardListComponent;
