import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { AccountApi } from "../../apiclient";
import {
  BillingPlan,
  MemberUserListItem,
  RoleModalViewModel,
  SendMagicLoginLinkModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import InviteMemberModal from "./InviteMemberModal";
import { Button, Row, Col } from "react-bootstrap";
import * as Swal from "sweetalert2";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import EditMemberModal from "./EditMemberModal";
import UpsellBanner from "../../components/UpsellBanner";
import SettingsNavMenu from "../../components/SettingsNavMenu";

const UsersPage = () => {
  let accountApi = new AccountApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const [isInviteMemberModalShown, setIsInviteMemberModalShown] =
    useState(false);
  const [isEditMemberModalShown, setIsEditMemberModalShown] = useState(false);
  const [users, setUsers] = useState([] as MemberUserListItem[]);
  const [selectedMemberToEdit, setSelectedMemberToEdit] = useState(
    {} as RoleModalViewModel
  );
  useEffect(() => {
    refreshUsers();
  }, []);

  const refreshUsers = () => {
    accountApi
      .accountGetMembersGet()
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error: any) => { });
  };

  const editMemberOnClick = (li: MemberUserListItem) => {
    var member = {
      emailAddress: li.emailAddress,
      jobTitle: li.jobTitle,
      name: li.name,
      userRole: li.role,
    } as RoleModalViewModel;
    setSelectedMemberToEdit(member);
    setIsEditMemberModalShown(true);
  };

  const confirmDeleteUser = (emailAddress: string) => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `Remove "${emailAddress}" as a member of this account?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove member",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteUser(emailAddress);
        }
      });
  };

  const resendLink = (emailAddress: string) => {
    var resendModel = {
      emailAddress: emailAddress,
    } as SendMagicLoginLinkModel;
    accountApi
      .accountSendMagicLoginLinkPost("1", resendModel)
      .then((response) => {
        if (response) toast.success("Invite sent");
        else toast.error("Failed to send invite");
      })
      .catch((error: any) => {
        console.log("post error", error);
        toast.error("Failed to send invite");
      });
  };

  const deleteUser = (emailAddress: string) => {
    accountApi
      .accountDeleteMemberDelete(emailAddress)
      .then((response) => {
        if (response)
          toast.success(
            "Member deleted. They will no longer have access to this account"
          );
        else toast.error("You cannot remove owners");

        refreshUsers();
      })
      .catch((error: any) => {
        console.log("post error", error);
        toast.error("Failed to remove member");
      });
  };

  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="border-bottom pt-6">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12">
                <h1 className="h2 ls-tight">Team</h1>
              </div>
              <div className="col-sm-6 col-12">
                {/* <a
                  href="https://help.wrappedgiftcard.com/en/collections/3830625-account#team"
                  target={"_blank"}
                  className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block float-end"
                >
                  <i className="bi bi-question-circle-fill"></i>
                  <span className="d-none d-sm-inline ms-2">Help articles</span>
                </a> */}
              </div>
            </div>
            <SettingsNavMenu />
          </div>
        </div>
      </header>
      <div className="py-6">
        <div className="container-fluid max-w-screen-md vstack gap-6">
          <div className="card">
            <div className="card-body pb-0">
              <div className="mb-4">
                <Row>
                  <Col>
                    <h4 className="mb-1">Manage access</h4>
                    <p className="text-sm text-muted">
                      Manage and invite team members. <a className="text-link" href="https://help.wrappedgiftcards.com/en/articles/9799149-users-and-permissions" target="_blank">Learn more.</a>
                    </p>
                  </Col>
                  <Col>
                    {(authContext.account?.isAdmin ||
                      authContext.account?.isOwner) && (
                        <div>
                            <a
                              className="btn btn-sm btn-primary float-end"
                              onClick={() => setIsInviteMemberModalShown(true)}
                            >
                              Invite member
                            </a>
                        </div>
                      )}
                  </Col>
                </Row>
              </div>
              <hr />
              <div className="list-group list-group-flush">
                {users &&
                  users?.map((user, i) => (
                    <div
                      key={"user" + i}
                      className="list-group-item d-flex align-items-center"
                    >
                      <div className="col">
                        <span className="d-block h6 font-semibold mb-1">
                          {user.name}
                        </span>
                        <p className="text-muted">{user.jobTitle}</p>
                      </div>
                      <div className="col">
                        <span className="d-block text-sm text-muted">
                          {user.emailAddress}
                        </span>
                      </div>
                      <div className="col text-center">
                        <span className="badge text-uppercase bg-soft-primary text-primary rounded-pill">
                          {user.role}
                        </span>
                      </div>
                      <div className="ms-auto text-end">
                        {/* <a href="#" className="btn btn-sm btn-square btn-neutral me-1"><i className="bi bi-pencil"></i> </a> */}
                        <button
                          onClick={() => editMemberOnClick(user)}
                          type="button"
                          className="btn btn-sm mr-10 btn-neutral"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => resendLink(user.emailAddress ?? "")}
                          type="button"
                          className="btn btn-sm mr-10 btn-neutral"
                        >
                          Re-send login link
                        </button>
                        {user.canDelete && (
                          <button
                            onClick={() =>
                              confirmDeleteUser(user.emailAddress ?? "")
                            }
                            type="button"
                            className="btn btn-sm btn-square btn-neutral text-danger-hover"
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditMemberModal
        show={isEditMemberModalShown}
        onHide={() => setIsEditMemberModalShown(false)}
        refreshUsers={refreshUsers}
        member={selectedMemberToEdit}
      />

      <InviteMemberModal
        show={isInviteMemberModalShown}
        onHide={() => setIsInviteMemberModalShown(false)}
        refreshUsers={refreshUsers}
      />
    </React.Fragment>
  );
};

export default UsersPage;
