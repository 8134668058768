import React, { useContext, useState, useEffect, useRef } from "react";
import TimezoneSelect from "react-timezone-select";
import { AssetApi } from "../../apiclient";
import {
  BillingPlan,
  GiftCardImageAssetViewModel,
  GiftToFriendPageSettingsViewModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Tabs,
  Tab,
  InputGroup,
} from "react-bootstrap";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import ContentEditable from "react-contenteditable";
import * as Swal from "sweetalert2";
import { Droppable, Draggable } from "react-beautiful-dnd";
import "./assets.css";
import SelectAndUploadAssetImagesModal from "./SelectAndUploadAssetImagesModal";
import UpsellBanner from "../../components/UpsellBanner";
const GiftToFriendSettingsPage = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [isLoading, setLoading] = useState(false as boolean);
  const [showModal, setShowModal] = useState(false);
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as GiftToFriendPageSettingsViewModel);
  const [validated, setValidated] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [images, setImages] = useState([] as GiftCardImageAssetViewModel[]);

  const [giftEmailHtml, setGiftEmailHtml] = useState("");
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument;
      if (iframeDocument) {
        iframeDocument.open();
        iframeDocument.write(giftEmailHtml);
        iframeDocument.close();
        console.log("giftEmailHtml");
      }
    }
  }, [giftEmailHtml, refreshCount]);

  const openModal = () => {
    console.log("open modal");
    setShowModal(true);
  };

  const closeModal = () => {
    console.log("close modal");
    setShowModal(false);
  };

  useEffect(() => {
    fetchData();
    fetchAssetImages();
    authContext.refresh();
  }, []);

  const fetchData = () => {
    assetApi
      .assetGiftToFriendPageSettingsGet()
      .then((response) => {
        var data = response.data;
        setModel(data);
        setLoading(false);
        console.log("fetch data", data);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const fetchAssetImages = () => {
    assetApi
      .assetActiveImageAssetsIdGet(authContext.account?.accountId!)
      .then((response) => {
        var data = response.data;
        console.log("get images", data);
        setImages(data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log("get images error", error);
        setLoading(false);
      });
  };

  const refreshImages = () => {
    fetchAssetImages();
    setRefreshCount(refreshCount + 1);
  };

  const saveChanges = () => {
    assetApi
      .assetUpdateGiftToFriendPageSettingsPost("1", model)
      .then((response) => {
        toast.success("Settings saved!");
        setUnsavedChanges(false);
        setRefreshCount(refreshCount + 1);
      })
      .catch((error: any) => {
        toast.error("An error occurred when saving settings.");
        setUnsavedChanges(false);
      });
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: GiftToFriendPageSettingsViewModel) => ({
      ...values,
      [name]: value,
    }));

    setUnsavedChanges(true);
  };

  const confirmBrandingResetDefaults = () => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `You want to reset your branding settings back to original.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Reset",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          resetBrandingDefaults();
        }
      });
  };

  const resetBrandingDefaults = () => {
    setModel((values: GiftToFriendPageSettingsViewModel) => ({
      ...values,
      backgroundColor: "#F5F9FC",
      buttonColor: "#5c60f5",
      cardColor: "#ffffff",
      font: "Segoe UI",
      primaryColor: "#16192c",
    }));
    setUnsavedChanges(true);
  };

  const confirmTranslationResetDefaults = () => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `You want to reset your translation settings back to original.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Reset",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          resetTranslationDefaults();
        }
      });
  };

  const resetTranslationDefaults = () => {
    setModel((values: GiftToFriendPageSettingsViewModel) => ({
      ...values,
      giftAmountHeaderTitle: "Gift {{amount}} to a friend",
      pageHeaderTitle: "{{companyName}}",
      ImageSectionHeaderLabel: "Select card image",
      giftCardSectionHeaderLabel: "Gift card details and message",
      giftCardFromNameFormLabel: "From Name:",
      giftCardFriendNameFormLabel: "Friend's name:",
      giftCardFriendEmailFormLabel: "Friend's email address:",
      giftCardMessageFormLabel: "Gift Message:",
      giftCardRecordNewVideoButtonText: "Record new video",
      giftCardSendNowButtonText: "Send now",
      giftCardSendLaterButtonText: "Send later",
      giftCardSendAsGiftButtonText: "Send as gift",
      giftCardRecordVideoButtonText: "Record a video message (optional)",
      giftCardUploadExistingVideoButtonText: "Upload existing video",
      giftCardRecordVideoModalHeaderLabel: "Record message",
      emailPreviewHeaderLabel: "Email preview",
      giftCardSendAtFormLabel: "Send At:",
      giftCardTimezoneFormLabel: "Timezone:",
      defaultGiftMessage:
        "Best wishes for your birthday! Hope you enjoy this gift card!",
    }));
    setUnsavedChanges(true);
  };

  const handleImageSelect = (image: any) => {
    //setSelectedImage(image);
  };

  const onRemoveHeaderImage = () => {
    //setSelectedImage(image);
    setModel({ ...model, pageHeaderImageUrl: null });
    setUnsavedChanges(true);
  };

  const handleFontChange = (event: any) => {
    const newFont: string = event.target.value;
    setModel({ ...model, font: newFont });
    setUnsavedChanges(true);
  };

  const handleWebFontChange = (event: any) => {
    const target = event.target;
    setModel({ ...model, useCustomFont: target.checked });
    setUnsavedChanges(true);
  };

  const handleWebFontNameChange = (event: any) => {
    const fontName: string = event.target.value;
    setModel({ ...model, customFont: fontName });
    setUnsavedChanges(true);
  };

  const handleWebFontURLChange = (event: any) => {
    const fontURL: string = event.target.value;
    setModel({ ...model, customFontUrl: fontURL });
    setUnsavedChanges(true);
  };

  const onFileInputChange = (event: any) => {
    let file = event.target.files[0];
    let extension = file.name.split(".").at(-1);
    let allowedFileExtensions = ["jpg", "png", "jpeg", "gif", "bmp"];
    if (!allowedFileExtensions.includes(extension.toLowerCase())) {
      toast.error("Incorrect file format, failed to import");
      // clear the file input
      event.target.value = null;
      return;
    }

    var reader = new FileReader();
    reader.onload = (e) => {
      if (e.target !== null) {
        var image = new Image();
        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result as string;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = image.height;
          var width = image.width;
          // if (height > 150 || width > 600) {
          //      toast.error(
          //"File size is too large, maximum dimensions are 600x150"
          //);
          //     // clear the file input
          //     event.target.value = null;
          //     setImageData(null);
          //     setModel({ ...model, customLogoImageUrl: undefined });
          //     return false;
          // }
        };
      }
    };
    reader.readAsDataURL(event.currentTarget.files[0]);
    //upload file
    var uploadFile = event.currentTarget.files[0];
    assetApi
      .assetUploadGiftToFriendPageHeaderImagePost("1", uploadFile)
      .then((response) => {
        var data = response.data;
        setModel({ ...model, pageHeaderImageUrl: data });
        setRefreshCount(refreshCount + 1);
      })
      .catch((error: any) => {});
  };
  return (
    <React.Fragment>
       <UpsellBanner message="Start your trial to use this feature" />
      <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
        <div className="container-fluid py-4">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-flex align-items-center gap-4">
                <div>
                  <Link
                    className="bi bi-chevron-left text-xs"
                    to={`/assets`}
                  ></Link>
                </div>
                <div className="vr opacity-20 my-1"></div>
                <h1 className="h4 ls-tight">Gift to a friend page</h1>
              </div>
            </div>
            <div className="col-auto">
              <div className="hstack gap-2 justify-content-end">
                {hasUnsavedChanges && (
                  <div className="text-sm text-muted font-semibold me-2 d-none d-md-block">
                    <span className="d-none d-sm-inline ms-2">
                      You have unsaved changes
                    </span>
                  </div>
                )}
                <a
                  type="button"
                  className="btn btn-neutral btn-sm"
                  target="_blank"
                  href={`https://manage.wrappedgiftcards.com/public/gift-to-friend/test`}
                >
                  <span>Preview</span>
                </a>
                {authContext.account?.billingPlan !== "Free" ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={saveChanges}
                    >
                      <span>Save</span>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      disabled
                    >
                      <span>Save</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      {isLoading ? (
        <>
          <LoaderIcon />
        </>
      ) : (
        <div className="d-xl-flex row">
          <div className=" bg-surface-primary col-md-6">
            <Tabs defaultActiveKey="designs" className="mb-3 ml-10" fill>
              <Tab eventKey="designs" title="Card images">
                <div className="ml-10 list-group">
                  <div className="list-group-item shadow-1 border rounded mb-5">
                    <button
                      onClick={openModal}
                      className="btn btn-sm btn-neutral float-end"
                    >
                      Manage
                    </button>
                    <h4 className="font-semibold mb-2">Active card images</h4>
                    <hr />
                    <Row>
                      {images.map((image) => (
                        <Col key={image.id} xs={6} sm={6} md={4}>
                          <label
                            className=""
                            onClick={() => handleImageSelect(image)}
                          >
                            <img
                              src={image.imageUrl}
                              alt={`Image ${image.name}`}
                            />
                          </label>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="branding" title="Branding">
                <div className="ml-10 list-group">
                  <div className="list-group-item shadow-1 border rounded mb-5">
                    <h4 className="font-semibold mb-2">Header banner image</h4>
                    <hr />
                    {model.pageHeaderImageUrl && (
                      <>
                        <img
                          className="mb-3"
                          style={{
                            maxWidth: 800,
                          }}
                          src={model.pageHeaderImageUrl}
                        ></img>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={onRemoveHeaderImage}
                        >
                          Remove
                        </button>
                      </>
                    )}
                    <div className="d-flex px-5 py-10">
                      <div className="file-upload-border rounded bg-primary bg-opacity-10 bg-opacity-20-hover border-2 border-primary border-dashed border-primary-hover position-relative">
                        <div className="d-flex justify-content-center px-5 py-10">
                          <label
                            htmlFor="file-upload"
                            className="position-absolute w-full h-full top-0 start-0 cursor-pointer"
                          >
                            {/* <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="visually-hidden"
                    /> */}
                            <Form.Control
                              onChange={(e) => onFileInputChange(e)}
                              name="file-upload"
                              id="file-upload"
                              className="visually-hidden"
                              type="file"
                              accept="image/jpg, image/png, image/jpeg, image/gif, image/bmp"
                            />
                          </label>
                          <div className="text-center">
                            <div className="text-2xl text-primary">
                              <i className="bi bi-cloud-upload-fill"></i>
                            </div>
                            <div className="d-flex text-sm text-primary mt-3">
                              <p className="font-semibold">
                                Upload a file or drag and drop
                              </p>
                            </div>
                            <p className="text-xs text-primary">
                              PNG, JPG, GIF up to 3MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item shadow-1 border rounded mb-5">
                    <h4 className="font-semibold mb-2">Colors</h4>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-sm-2">
                        {/* <input
                          type="color"
                          className="form-control form-control-color"
                          id="backgroundColor"
                          value="#F3F3F3"
                          title="Choose your color"
                        /> */}
                        <Form.Control
                          type="color"
                          name="backgroundColor"
                          value={model.backgroundColor}
                          title="Background color"
                          onChange={handleChange}
                        />
                      </div>
                      <label
                        htmlFor="backgroundColor"
                        className="col-sm-10 col-form-label"
                      >
                        Background color
                      </label>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-2">
                        {/* <input
                          type="color"
                          className="form-control form-control-color"
                          id="cardColor"
                          value="#FFFFFF"
                          title="Choose your color"
                        /> */}
                        <Form.Control
                          type="color"
                          name="cardColor"
                          value={model.cardColor}
                          title="Card color"
                          onChange={handleChange}
                        />
                      </div>
                      <label
                        htmlFor="cardColor"
                        className="col-sm-10 col-form-label"
                      >
                        Card color
                      </label>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-2">
                        {/* <input
                          type="color"
                          className="form-control form-control-color"
                          id="primaryColor"
                          value="#023246"
                          title="Choose your color"
                        /> */}
                        <Form.Control
                          type="color"
                          name="primaryColor"
                          value={model.primaryColor}
                          title="Primary color"
                          onChange={handleChange}
                        />
                      </div>
                      <label
                        htmlFor="primaryColor"
                        className="col-sm-10 col-form-label"
                      >
                        Primary color
                      </label>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-2">
                        {/* <input
                          type="color"
                          className="form-control form-control-color"
                          id="buttonColor"
                          value="#023246"
                          title="Choose your color"
                        /> */}
                        <Form.Control
                          type="color"
                          name="buttonColor"
                          value={model.buttonColor}
                          title="Button color"
                          onChange={handleChange}
                        />
                      </div>
                      <label
                        htmlFor="buttonColor"
                        className="col-sm-10 col-form-label"
                      >
                        Button color
                      </label>
                    </div>
                  </div>
                  <div className="list-group-item shadow-1 border rounded mb-5">
                    <h4 className="font-semibold mb-2">Font</h4>
                    <hr />
                    <Form.Group className="mb-3">
                      <Form.Label>Default Font</Form.Label>
                      <Form.Select
                        name="font"
                        onChange={handleFontChange}
                        value={model?.font}
                      >
                        <option value="'Sergo UI'">Sergio UI</option>
                        <option value="'Open Sans', sans-serif">
                          Open Sans
                        </option>
                        <option value="Georgia, serif">Georgia</option>
                        <option value="'Palatino Linotype', 'Book Antiqua', Palatino, serif">
                          Palatino Linotype
                        </option>
                        <option value="'Times New Roman', Times, serif">
                          Times New Roman
                        </option>
                        <option value="Andale Mono,AndaleMono,monospace">
                        Andale Mono
                        </option>
                        <option value="Arial, Helvetica, sans-serif">
                          Arial
                        </option>
                        <option value="'Arial Black', Gadget, sans-serif">
                          Arial Black
                        </option>
                        <option value="'Comic Sans MS', cursive, sans-serif">
                          Comic Sans MS
                        </option>
                        <option value="Impact, Charcoal, sans-serif">
                          Impact
                        </option>
                        <option value="'Lucida Sans Unicode', 'Lucida Grande', sans-serif">
                          Lucida Sans Unicode
                        </option>
                        <option value="Tahoma, Geneva, sans-serif">
                          Tahoma
                        </option>
                        <option value="'Trebuchet MS', Helvetica, sans-serif">
                          Trebuchet MS
                        </option>
                        <option value="Verdana, Geneva, sans-serif">
                          Verdana
                        </option>
                        <option value="'Courier New', Courier, monospace">
                          Courier New
                        </option>
                        <option value="'Lucida Console', Monaco, monospace">
                          Lucida Console
                        </option>
                      </Form.Select>
                    </Form.Group>
                    {/* <Form.Group className="mb-3">
                      <Form.Check
                        checked={model?.useCustomFont || false}
                        onChange={handleWebFontChange}
                        name="primaryUseWebFont"
                        id="use-google-font-primary"
                        type="checkbox"
                        label="Use Google Font"
                      />
                    </Form.Group>
                    {model?.useCustomFont && (
                      <div>
                        <p>
                          Use{" "}
                          <a
                            href="https://fonts.google.com/"
                            className="text-link"
                            target="_blank"
                          >
                            Google Font
                          </a>{" "}
                          by entering the font's name and URL.
                        </p>
                        <Form.Group className="mb-3">
                          <Form.Label>Google Font Name</Form.Label>
                          <Form.Control
                            name="customFont"
                            type="text"
                            onChange={handleWebFontNameChange}
                            value={
                              model?.customFont == null ? "" : model?.customFont
                            }
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Google Font URL</Form.Label>
                          <Form.Control
                            name="primaryFontUrl"
                            type="text"
                            onChange={handleWebFontURLChange}
                            value={
                              model?.customFontUrl == null
                                ? ""
                                : model?.customFontUrl
                            }
                          />
                        </Form.Group>
                      </div>
                    )} */}
                  </div>

                </div>
                <div className="ml-10 list-group">
                  <div className="list-group-item shadow-1 border rounded mb-5">
                    <Form.Group className="mb-3">
                      <Form.Check
                        checked={model?.hideWrappedBranding || false}
                        onChange={handleChange}
                        name="hideWrappedBranding"
                        id="hideWrappedBranding"
                        type="checkbox"
                        label="Hide powered by Wrapped footer"
                      />
                    </Form.Group>

                  </div>
                </div>
                <div className="ml-10 d-grid gap-2 mx-auto">
                  <button
                    className="btn btn-neutral"
                    type="button"
                    onClick={confirmBrandingResetDefaults}
                  >
                    Reset to defaults
                  </button>
                </div>
              </Tab>
              <Tab eventKey="translation" title="Translation">
                <div className="ml-10 list-group">
                  <div className="list-group-item shadow-1 border rounded mb-5">
                    <h4 className="font-semibold mb-2">Translation</h4>
                    <hr />
                    <div className="row mb-3">
                      <label
                        htmlFor="defaultGiftMessage"
                        className="col-sm-10 col-form-label"
                      >
                        Default gift message
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="defaultGiftMessage"
                          value={model.defaultGiftMessage}
                          title="Default gift message"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="pageHeaderTitle"
                        className="col-sm-10 col-form-label"
                      >
                        Page header title
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="pageHeaderTitle"
                          value={model.pageHeaderTitle}
                          title="Page header title"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardSectionHeaderLabel"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section header
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardSectionHeaderLabel"
                          value={model.giftCardSectionHeaderLabel}
                          title="Gift card section header"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardFromNameFormLabel"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section "from" field label
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardFromNameFormLabel"
                          value={model.giftCardFromNameFormLabel}
                          title="Gift card section from field label"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardFriendNameFormLabel"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section "friend's name" field label
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardFriendNameFormLabel"
                          value={model.giftCardFriendNameFormLabel}
                          title="Gift card section friend's name field label"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardFriendNameFormLabel"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section "friend's email" field label
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardFriendEmailFormLabel"
                          value={model.giftCardFriendEmailFormLabel}
                          title="Gift card section friend's email field label"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardMessageFormLabel"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section "message" field label
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardMessageFormLabel"
                          value={model.giftCardMessageFormLabel}
                          title="Gift card section message field label"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardRecordVideoButtonText"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section record video button text
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardRecordVideoButtonText"
                          value={model.giftCardRecordVideoButtonText}
                          title="Gift card record video button text"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardSendNowButtonText"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section "send now" button text
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardSendNowButtonText"
                          value={model.giftCardSendNowButtonText}
                          title="Gift card send now button text"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardSendLaterButtonText"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section "send later" button text
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardSendLaterButtonText"
                          value={model.giftCardSendLaterButtonText}
                          title="Gift card send later button text"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardSendAtFormLabel"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section "send at" form label"
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardSendAtFormLabel"
                          value={model.giftCardSendAtFormLabel}
                          title="Gift card send at form label"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardTimezoneFormLabel"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section "timezone" form label"
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardTimezoneFormLabel"
                          value={model.giftCardTimezoneFormLabel}
                          title="Gift card timezone form label"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardSendAsGiftButtonText"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card section "send as gift" button text"
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardSendAsGiftButtonText"
                          value={model.giftCardSendAsGiftButtonText}
                          title="Gift card send as gift button text"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardSendAsGiftButtonText"
                        className="col-sm-10 col-form-label"
                      >
                        Gift card popup "Add to cart" button text" (Shopify only)
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="addToCartButtonText"
                          value={model.addToCartButtonText}
                          title="Gift card add to cart button text"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardRecordNewVideoButtonText"
                        className="col-sm-10 col-form-label"
                      >
                        Upload modal "record new video" button text"
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardRecordNewVideoButtonText"
                          value={model.giftCardRecordNewVideoButtonText}
                          title="Upload modal record new video button text"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardUploadExistingVideoButtonText"
                        className="col-sm-10 col-form-label"
                      >
                        Upload modal "Upload existing video" button text"
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardUploadExistingVideoButtonText"
                          value={model.giftCardUploadExistingVideoButtonText}
                          title="Upload modal upload existing video button text"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="giftCardRecordVideoModalHeaderLabel"
                        className="col-sm-10 col-form-label"
                      >
                        Upload modal "record message" header label"
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="giftCardRecordVideoModalHeaderLabel"
                          value={model.giftCardRecordVideoModalHeaderLabel}
                          title="Upload modal record message header label"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="emailPreviewHeaderLabel"
                        className="col-sm-10 col-form-label"
                      >
                        Email preview header label
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="emailPreviewHeaderLabel"
                          value={model.emailPreviewHeaderLabel}
                          title="Email preview header label"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-10 d-grid gap-2 mx-auto mb-20">
                  <button
                    className="btn btn-neutral"
                    type="button"
                    onClick={confirmTranslationResetDefaults}
                  >
                    Reset to defaults
                  </button>
                </div>
              </Tab>
              <Tab eventKey="video" title="Video settings">
                <div className="ml-10 list-group">
                  <div className="list-group-item shadow-1 border rounded mb-5">
                    <h4 className="font-semibold mb-2">Video settings</h4>
                    <hr />
                    <div className="row mb-3">
                      <label
                        htmlFor="isVideoMessageEnabled"
                        className="col-sm-10 col-form-label"
                      >
                        Video messages enabled?
                      </label>
                      <div className="col-sm-12">
                        <Form.Check
                          type="switch"
                          name="isVideoMessageEnabled"
                          checked={model.isVideoMessageEnabled}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
          <div
            className="col-md-6 flex-xl-none border-start-xl h-xl-calc position-sticky top-xl-18"
            style={{
              backgroundColor: model.backgroundColor,
              fontFamily: model.font
            }}
          >
            <aside className="p-8 h-full">
              <div className="vstack gap-6">
                <iframe
                  src={`/public/gift-to-friend/test?rs=${refreshCount}`}
                  sandbox="allow-same-origin allow-scripts allow-forms=false"
                  title="Email content"
                  style={{
                    width: "100%",
                    maxHeight: "100%",
                    height: "1350px",
                    border: "none",
                    outline: "none",
                  }}
                />
              </div>
            </aside>
          </div>
        </div>
      )}
      <SelectAndUploadAssetImagesModal
        show={showModal}
        onHide={closeModal}
        refreshImages={refreshImages}
      />
    </React.Fragment>
  );
};

export default GiftToFriendSettingsPage;
