import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AccountContext";
import { BillingPlan } from "../apiclient";
import { useIntercom } from "react-use-intercom";
const ProPlanUpsellBanner = (props: any) => {
  const authContext = useContext(AuthContext);
  const [isValidPlan, setIsValidPlan] = useState(true);
  const [message, setMessage] = useState(props.message as string);
  const {
    boot,
    shutdown,
    hardShutdown,
    update,
    hide,
    show,
    showMessages,
    showNewMessages,
    getVisitorId,
    startTour,
    trackEvent,
    showArticle,
  } = useIntercom();

  function openChatForMarketingRequest() {
    showNewMessages("I'd like to inquire about the marketing features.");
  }

  useEffect(() => {
    setIsValidPlan((authContext.account?.billingPlan === BillingPlan.Pro || authContext.account?.billingPlan === BillingPlan.ProAnnual || authContext.account?.billingPlan === BillingPlan.Plus || authContext.account?.billingPlan === BillingPlan.PlusAnnual) || authContext.account?.marketingFeaturesEnabled!);
    if (!authContext.account?.marketingFeaturesEnabled! && authContext.account?.billingPlan === BillingPlan.Enterprise) {
      setMessage("This feature has not been enabled for your account. Please contact us to get it enabled.")
    }

  }, []);

  return (
    <React.Fragment>
      {!isValidPlan ? (<div className="alert alert-warning" role="alert">
        <div className="row align-items-center">
          {message ? (<div className="col font-bold">
            {message}
          </div>) : (<div className="col font-bold">
            This feature is only available on the Pro and Plus plans.
          </div>)}

          <div className="col-auto">
            <div className="hstack gap-2 justify-content-end">
              {!authContext.account?.marketingFeaturesEnabled! && authContext.account?.billingPlan === BillingPlan.Enterprise  ? (
                <button className="btn d-inline-flex btn-sm btn-primary mx-1" type="button" onClick={() => openChatForMarketingRequest()}>Contact us</button>
                ) : <Link
                to={"/billingplan"}
                className="btn d-inline-flex btn-sm btn-primary mx-1"
              >
                <span className=" pe-2">
                  <i className="bi bi-unlock-fill"></i>
                </span>
                <span>Upgrade</span>
              </Link>}
            </div>
          </div>
        </div>
      </div>) : (<></>)}

    </React.Fragment>
  );
};

export default ProPlanUpsellBanner;
