import React, { useContext, useState, useEffect } from "react";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  AddGiftCardToSalePostPayload,
  GiftCardDetailViewModel,
  GiftCardViewModel,
  GiftcardApi,
  LSSale,
  RSeriesApi,
} from "../../apiclient";
import { useZxing } from "react-zxing";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { BarcodeScanner } from "./BarcodeScanner";
//import { Html5Qrcode } from "html5-qrcode";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/relativeTime"));

const RSeriesGiftCardPage = () => {
  let { id } = useParams();
  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  let rseriesApi = new RSeriesApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPanel, setShowPanel] = useState("choose");
  const [giftCardModel, setGiftCardModel] = useState(
    {} as GiftCardDetailViewModel
  );
  const [giftCardNumber, setGiftCardNumber] = useState("");
  const [giftCardProductId, setGiftCardProductId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [topupAmount, setTopupAmount] = useState(0);
  const [currentSaleTotal, setCurrentSaleTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showBarcodeScannerModal, setShowBarcodeScannerModal] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [facingMode, setFacingMode] = useState("user");
  const returnURL = searchParams.get("returnURL") ?? "";
  const saleId = searchParams.get("saleID") ?? "";
  const customerId = searchParams.get("customerID") ?? "";
  const [digitalCodeErrorMessage, setDigitalCodeErrorMessage] = useState("");

  useEffect(() => {
    setRefreshCount(refreshCount + 1);
  }, [facingMode, showBarcodeScannerModal]);

  useEffect(() => {
    setAccountId(id!);
    fetchProductId();
    getSaleById();
  }, []);

  const onInBuiltScanSuccess = (result: any) => {
    var decodedText = result.getText();
    setGiftCardNumber(decodedText);
    stopScanner();
    fetchGiftCardByCode(decodedText);
  };

  const showScanner = () => {
    setShowBarcodeScannerModal(true);
  };

  const stopScanner = () => {
    setShowBarcodeScannerModal(false);
  };

  const generateDigitalGiftCardOnClick = () => {
    if (customerId !== "0") {
      giftcardApi
        .giftcardGenerateUniqueCodeByAccountIdGet(accountId)
        .then((response) => {
          setGiftCardNumber(response.data);
          fetchGiftCardByCode(response.data);
          setDigitalCodeErrorMessage("");
        });
    } else {
      setDigitalCodeErrorMessage("You need to add a customer to the sale.");
    }
  };

  const filpCamera = () => {
    var newFacingMode = facingMode === "environment" ? "user" : "environment";
    setFacingMode(newFacingMode);
  };

  const fetchProductId = () => {
    giftcardApi
      .giftcardGetGiftCardProductIdAccountPlatformIdGet(id!)
      .then((response: any) => {
        if (response.data) {
          setAccountId(response.data.accountId);
          setGiftCardProductId(response.data.giftCardProductId);
        }
      })
      .catch((error) => {
        console.log("post error", error);
      });
  };

  const getSaleById = () => {
    rseriesApi
      .rSeriesGetLSSaleByIdGet(saleId!)
      .then((response) => {
        if (response.data) {
          setCurrentSaleTotal(response.data.totalDue!);
        }
      })
      .catch((error) => {
        console.log("post error", error);
      });
  };

  const handleSubmitGiftCard = (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    const form = event.currentTarget;

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      fetchGiftCardByCode(giftCardNumber);
    }
  };

  const fetchGiftCardByCode = (code: string) => {
    giftcardApi
      .giftcardGetByCodeCodeGet(code, accountId)
      .then((response) => {
        if (response.data) {
          setGiftCardModel(response.data);
          setShowPanel("details");
        } else {
          setShowPanel("new");
        }
        console.log("post error", response.data);
      })
      .catch((error) => {
        console.log("post error", error);
        setGiftCardModel({});
      })
      .finally(() => {
        setValidated(false);
      });
  };

  const handleSubmitTopup = (event: any) => {
    //addGiftCardItemToSale();
    event.preventDefault();
    setIsSubmitting(true);
    const form = event.currentTarget;

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      addGiftCardItemToSale();
    }
  };

  const resetGiftCard = () => {
    setGiftCardModel({});
    setGiftCardNumber("");
    setShowPanel("choose");
    setValidated(false);
    setTopupAmount(0);
    setDigitalCodeErrorMessage("");
  };

  const addGiftCardItemToSale = () => {
    var payload = {
      amount: topupAmount,
      accountPlatformId: id!,
      saleId: saleId!,
      giftCardCode: giftCardNumber,
    } as AddGiftCardToSalePostPayload;
    rseriesApi
      .rSeriesAddGiftCardProductToSalePost("1", payload)
      .then((response: any) => {
        if (response.data) {
          window.location.href = returnURL;
        }
      })
      .catch((error) => {
        console.log("post error", error);
      });
  };

  const navigateToTopup = () => {
    setTopupAmount(0);
    setValidated(false);
    setShowPanel("topup");
  };

  const makePayment = () => {
    var payload = {
      amount: giftCardModel.balance,
      accountPlatformId: id!,
      saleId: saleId!,
      giftCardCode: giftCardNumber,
    } as AddGiftCardToSalePostPayload;
    rseriesApi
      .rSeriesApplyGiftCardPaymentToSalePost("1", payload)
      .then((response: any) => {
        if (response.data) {
          window.location.href = returnURL;
        }
      })
      .catch((error) => {
        console.log("post error", error);
      });
  };

  const handleGiftCardChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setGiftCardNumber(value);
    setIsSubmitting(false);
  };

  const handleTopupChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setTopupAmount(value);
    setIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <div id="list-account-content"></div>
      <nav className="navbar navbar-dark bg-dark mx-auto text-white">
        {showBarcodeScannerModal ? (
          <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
            <button
              className="btn btn-sm btn-neutral w-full w-lg-auto"
              onClick={stopScanner}
            >
              Back
            </button>
          </div>
        ) : (
          <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
            <a
              href={returnURL}
              className="btn btn-sm btn-neutral w-full w-lg-auto"
            >
              Back
            </a>
          </div>
        )}
        <ul className="navbar-nav mx-auto">
          <li>
            <a className="navbar-brand">
              <div className="w-md-auto ">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="Omnichannel gift cards"
                />
              </div>
            </a>
          </li>
        </ul>
        {showBarcodeScannerModal && (
          <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
            <button
              onClick={filpCamera}
              className="btn btn-sm btn-neutral w-full w-lg-auto"
            >
              <img width={30} src="/img/assets/flip.png" />
            </button>
          </div>
        )}
      </nav>
      <div className="container-fluid">
        {showPanel === "choose" && (
          <div className="d-flex justify-content-center">
            <div className="col-12 col-md-9 col-lg-7 d-flex flex-column justify-content-center py-lg-16 position-relative">
              {!showBarcodeScannerModal ? (
                <div className="row">
                  <div className="mx-auto">
                    <div className="bg-surface-secondary rounded-md-4 shadow-md-6 p-5 p-md-10">
                      <div className="text-center mb-5 mb-md-10">
                        <h1 className="ls-tight font-bolder h2">Gift cards</h1>
                        {/* <p className="mt-2">
                            Add a new gift card to the sale, check the balance
                            of an existing gift card, or reload an existing gift
                            card.
                          </p> */}
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary w-full"
                          onClick={() => setShowPanel("enter")}
                        >
                          Find or activate gift card
                        </button>
                        {accountId && (
                          <>
                            <button
                              type="button"
                              onClick={generateDigitalGiftCardOnClick}
                              className="btn btn-neutral w-full mt-5"
                            >
                              Generate digital gift card
                            </button>
                            {customerId === "0" && (
                              <div className="mt-5 text-center text-danger">
                                {digitalCodeErrorMessage}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row"></div>
              )}
              {showBarcodeScannerModal && (
                <BarcodeScanner
                  paused={!showBarcodeScannerModal}
                  onScanSucess={onInBuiltScanSuccess}
                  facingMode={facingMode}
                  refreshCount={refreshCount}
                />
              )}
            </div>
          </div>
        )}
        {showPanel === "enter" && (
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmitGiftCard}
          >
            <div className="d-flex justify-content-center">
              <div className="col-12 col-md-9 col-lg-7 d-flex flex-column justify-content-center py-lg-16 position-relative">
                {!showBarcodeScannerModal ? (
                  <div className="row">
                    <div className="mx-auto">
                      <div className="bg-surface-secondary rounded-md-4 shadow-md-6 p-5 p-md-10">
                        <div className="text-center mb-5 mb-md-10">
                          <h1 className="ls-tight font-bolder h2">
                            Add new or manage existing gift card.
                          </h1>
                          <p className="mt-2">
                            Add a new gift card to the sale, check the balance
                            of an existing gift card, or reload an existing gift
                            card.
                          </p>
                        </div>
                        <div className="mb-5">
                          <label
                            className="form-label"
                            htmlFor="giftCardNumber"
                          >
                            Gift card number
                          </label>
                          <div className="input-group">
                            <Form.Control
                              name={"giftCardNumber"}
                              className="form-control form-control-muted"
                              type="text"
                              placeholder={"Enter gift card number"}
                              value={giftCardNumber}
                              onChange={handleGiftCardChange}
                              required
                            />
                            <div className="input-group-text">
                              <button
                                className="btn btn-neutral"
                                onClick={() => showScanner()}
                              >
                                <i className="bi bi-qr-code-scan"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            type="submit"
                            disabled={!giftCardNumber}
                            className="btn btn-primary w-full"
                          >
                            Find or activate gift card
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row"></div>
                )}
                {showBarcodeScannerModal && (
                  <BarcodeScanner
                    paused={!showBarcodeScannerModal}
                    onScanSucess={onInBuiltScanSuccess}
                    facingMode={facingMode}
                    refreshCount={refreshCount}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
        {showPanel === "new" && (
          <Form noValidate validated={validated} onSubmit={handleSubmitTopup}>
            <div className="d-flex justify-content-center">
              <div className="col-12 col-md-9 col-lg-7 d-flex flex-column justify-content-center py-lg-16 position-relative">
                <div className="row">
                  <div className="mx-auto">
                    <div className="bg-surface-secondary rounded-md-4 shadow-md-6 p-5 p-md-10">
                      <div className="text-center mb-5 mb-md-10">
                        <h1 className="ls-tight font-bolder h2">
                          Add new gift card to sale
                        </h1>
                        <p className="mt-2">
                          This gift card is not active and has no balance. Enter
                          a value to load a balance, then complete the sale to
                          activate this card.
                        </p>
                      </div>
                      <div className="text-center mb-5 mb-md-10">
                        <p className="">Gift card number</p>
                        <h1 className="ls-tight font-bolder h2">
                          {giftCardNumber}
                        </h1>
                      </div>
                      <div className="mb-5">
                        <label className="form-label" htmlFor="topup">
                          Amount to Add
                        </label>
                        <Form.Control
                          name={"topup"}
                          className="form-control form-control-muted"
                          type="number"
                          placeholder={"0.00"}
                          value={topupAmount}
                          onChange={handleTopupChange}
                          required
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          disabled={topupAmount <= 0}
                          className="btn btn-primary w-full"
                        >
                          Add to sale
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary w-full mt-3"
                          onClick={() => resetGiftCard()}
                        >
                          Find a different gift card
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
        {showPanel === "details" && giftCardModel && (
          <div className="d-flex justify-content-center">
            <div className="col-12 col-md-9 col-lg-7 d-flex flex-column justify-content-center py-lg-16 position-relative">
              <div className="row">
                <div className="mx-auto">
                  <div className="bg-surface-secondary rounded-md-4 shadow-md-6 p-5 p-md-10">
                    <div className="text-center mb-5 mb-md-10">
                      {/* <span  className="d-inline-block d-lg-block h1 mb-lg-6 me-3">🎁</span> */}
                      <h1 className="ls-tight font-bolder h2">
                        Gift card {giftCardModel.code}
                      </h1>
                      <p className="mt-2">
                        Issued {dayjs(giftCardModel.dateIssued).utc().fromNow()}
                      </p>
                      {giftCardModel.balance! * 100 < currentSaleTotal && (
                        <p className="text-danger">
                          This card has insufficient funds to complete the sale.
                          You may still use this gift card to make a partial
                          payment.
                        </p>
                      )}
                    </div>
                    <div className="text-center mb-5 mb-md-10">
                      <p className="">Balance</p>
                      <h1 className="ls-tight font-bolder h2">
                        {giftCardModel.formattedBalance}
                      </h1>
                    </div>
                    <hr className="mb-7 pe-lg-16" />
                    <div>
                      {giftCardModel.balance! * 100 < currentSaleTotal ? (
                        <button
                          onClick={() => makePayment()}
                          className="btn btn-primary w-full"
                        >
                          Make partial payment
                        </button>
                      ) : (
                        <button
                          onClick={() => makePayment()}
                          className="btn btn-primary w-full mt-3"
                        >
                          Pay with gift card
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => navigateToTopup()}
                        className="btn btn-secondary w-full mt-3"
                      >
                        Add funds to gift card
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary w-full mt-3"
                        onClick={() => resetGiftCard()}
                      >
                        Find a different gift card
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showPanel === "topup" && giftCardModel && (
          <Form noValidate validated={validated} onSubmit={handleSubmitTopup}>
            <div className="d-flex justify-content-center">
              <div className="col-12 col-md-9 col-lg-7 d-flex flex-column justify-content-center py-lg-16 position-relative">
                <div className="row">
                  <div className="mx-auto">
                    <div className="bg-surface-secondary rounded-md-4 shadow-md-6 p-5 p-md-10">
                      <div className="text-center mb-5 mb-md-10">
                        <h1 className="ls-tight font-bolder h2">
                          Add funds to gift card
                        </h1>
                        <p className="mt-2">
                          This gift card is active and has a balance. Enter a
                          value to add to the balance, then complete the sale to
                          add funds to this card.
                        </p>
                        <div className="row mt-10">
                          <div className="col">
                            <p className="mt-2">Gift card number</p>
                            <h1 className="ls-tight font-bolder h4">
                              {giftCardModel.code}
                            </h1>
                          </div>
                          <div className="col">
                            <p className="mt-2">Current balance</p>
                            <h1 className="ls-tight font-bolder h4">
                              {giftCardModel.formattedBalance}
                            </h1>
                          </div>
                        </div>
                      </div>

                      <div className="mb-5">
                        <label className="form-label" htmlFor="topup">
                          Amount to Add
                        </label>
                        <Form.Control
                          name={"topup"}
                          className="form-control form-control-muted"
                          type="number"
                          placeholder={"0.00"}
                          value={topupAmount}
                          onChange={handleTopupChange}
                          required
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          disabled={topupAmount <= 0}
                          className="btn btn-primary w-full"
                        >
                          Add to sale
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </div>
    </React.Fragment>
  );
};

export default RSeriesGiftCardPage;
