import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { ProfileApi, AssetApi } from "../../apiclient";
import { CustomEmbedScriptsViewModel } from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import AuthorizedAdminOrOwnerCheckComponent from "../../components/AuthorizedAdminOrOwnerCheckComponent";
import SettingsNavMenu from "../../components/SettingsNavMenu";

const AdvancedCustomizationPage = () => {
  let accountApi = new ProfileApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as CustomEmbedScriptsViewModel);

  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    assetApi
      .assetGetCustomEmbedScriptsGet()
      .then((response) => {
        setModel(response.data);
      })
      .catch((error: any) => {});
  };

  const handleSubmit = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }
    var submitModel = {
      checkBalancePageHeadScript: model.checkBalancePageHeadScript,
      giftToFriendPageHeadScript: model.giftToFriendPageHeadScript,
      storefrontHeadScript: model.storefrontHeadScript,
      giftToFriendPopupHeadScript: model.giftToFriendPopupHeadScript
    } as CustomEmbedScriptsViewModel;

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      assetApi
        .assetUpdateCustomEmbedScriptsPost("1", submitModel)
        .then((response) => {
          toast.success("Advanced customization saved");
          authContext.refresh();
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error saving advanced customization");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: CustomEmbedScriptsViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      <AuthorizedAdminOrOwnerCheckComponent />
      <header>
        <div className="container">
          <div className="border-bottom pt-6">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12">
                <h1 className="h2 ls-tight">Advanced customization</h1>
              </div>
              <div className="col-sm-6 col-12">
                {/* <a
                  href="https://help.wrappedgiftcard.com/en/collections/3830625-account#general"
                  target={"_blank"}
                  className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block float-end"
                >
                  <i className="bi bi-question-circle-fill"></i>
                  <span className="d-none d-sm-inline ms-2">Help articles</span>
                </a> */}
              </div>
            </div>
            <SettingsNavMenu />
          </div>
        </div>
      </header>

      <div className="bg-surface-secondary">
        <div className="container py-10">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row mb-10">
              <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                <h4 className="font-semibold mb-2">
                  Add your custom javascript and CSS code blocks
                </h4>
                <p className="text-sm">
                  This is for advanced users only.<br /> 
                  Include HTML tags. These will be added into the &lt;head&gt; block of the respective page.
                </p>
              </div>
              <div className="col-lg-8">
                <div className="card shadow border-0">
                  <div className="card-body">
                    <div className="row g-5">
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="checkBalancePageHeadScript">
                          Check Balance Page custom javascript/css
                        </label>
                        <Form.Control
                          as="textarea"
                          name={"checkBalancePageHeadScript"}
                          className="form-control"
                          value={model.checkBalancePageHeadScript || ""}
                          onChange={handleChange}
                          rows={5}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="giftToFriendPageHeadScript">
                          Gift to a Friend Page custom javascript/css
                        </label>
                        <Form.Control
                          as="textarea"
                          name={"giftToFriendPageHeadScript"}
                          className="form-control"
                          value={model.giftToFriendPageHeadScript || ""}
                          onChange={handleChange}
                          rows={5}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="giftToFriendPageHeadScript">
                          Gift to a Friend Shopify  Popup custom javascript/css
                        </label>
                        <Form.Control
                          as="textarea"
                          name={"giftToFriendPopupHeadScript"}
                          className="form-control"
                          value={model.giftToFriendPopupHeadScript || ""}
                          onChange={handleChange}
                          rows={5}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="companyName">
                          Wrapped Storefront custom javascript/css
                        </label>
                        <Form.Control
                          as="textarea"
                          name={"storefrontHeadScript"}
                          className="form-control"
                          value={model.storefrontHeadScript || ""}
                          onChange={handleChange}
                          rows={5}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="card-footer text-end py-4">
                    <button type="submit" className="btn btn-sm btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdvancedCustomizationPage;
