import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { AdminApi, ProfileApi, WooCommerceApi } from "../../apiclient";
import {
  AdminAccountProfileViewModel,
  AdminUpdateBusinessInformationViewModel,
  AdminUpdateContactInformationViewModel,
  AdminUpdateBillingInformationViewModel
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { Badge, Button } from "react-bootstrap";
import AdminCancelAccountModal from "./AdminCancelAccountModal";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const AdminAccountPage = () => {
  let accountApi = new AdminApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
  let { id } = useParams();
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as AdminAccountProfileViewModel);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCancelModalShown, setIsCancelModalShown] =
    useState(false);
  const timezoneOnChange = (selectedTimezone: any) => {
    console.log("selectedTimezone", selectedTimezone);
    setTimezone(selectedTimezone.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    accountApi
      .adminGetAccountByIdGet(id!)
      .then((response) => {
        setModel(response.data);
        console.log("profile load", response.data);
      })
      .catch((error: any) => { });
  };

  const handleSubmitContactInformation = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }
    var submitModel = {
      accountId: id,
      companyName: model.companyName,
      emailAddress: model.emailAddress,
      name: model.name,
      phone: model.phone,
      replyToEmailAddress: model.replyToEmailAddress
    } as AdminUpdateContactInformationViewModel;

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      accountApi
        .adminUpdateContactInformationPost("1", submitModel)
        .then((response) => {
          toast.success("Contact information saved!");
          authContext.refresh();
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error updating contact information.");
        });
    }
  };

  const handleSubmitBillingInformation = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }
    var submitModel = {
      accountId: id,
      billingAdditionalGiftCardPrice: model.billingAdditionalGiftCardPrice,
      billingNumberOfFreeGiftCards: model.billingNumberOfFreeGiftCards,
      billingShouldChargeForAdditionalGiftCards: model.billingShouldChargeForAdditionalGiftCards,
      billingSubscriptionId: model.billingSubscriptionId,
      isTest: model.isTest,
      notes: model.notes,
      marketingFeaturesEnabled: model.marketingFeaturesEnabled
    } as AdminUpdateBillingInformationViewModel;

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      accountApi
        .adminUpdateBillingInformationPost("1", submitModel)
        .then((response) => {
          toast.success("Billing information saved!");
          authContext.refresh();
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error updating billing information.");
        });
    }
  };

  const handleSubmitBusinessInformation = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }
    var submitModel = {
      accountId: id,
      businessCategory: model.businessCategory,
      businessType: model.businessType,
      ianaTimezone: timezone,
      isoCurrencyCode: model.isoCurrencyCode,
      website: model.website
    } as AdminUpdateBusinessInformationViewModel;

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      accountApi
        .adminUpdateBusinessInformationPost("1", submitModel)
        .then((response) => {
          toast.success("Business information saved!");
          authContext.refresh();
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error updating business information.");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: AdminAccountProfileViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const loginToAccount = (accountId: string) => {
    accountApi
      .adminAdminLoginByAccountIdGet(accountId)
      .then((response) => {
        toast.success("Logged in!");
        authContext.refresh();
        window.location.href = "/dashboard";
      })
      .catch((error: any) => {
        toast.error("Unable to login as an admin");
      });
  };

  const onAccountCancelled = () => {
   setIsCancelModalShown(false);
   fetchData();
  };


  return (
    <React.Fragment>
      <header>
        <div className="container">
          <Link
            className="btn btn-light btn-sm mt-10"
            to={`/admin/list`}
          ><i className="bi bi-arrow-left"></i> Back to accounts list</Link>
          <div className="border-bottom pt-6 pb-5">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12">
                <h1 className="h2 ls-tight">"{model.companyName}" account details</h1>
              </div>
              <div className="col-sm-6 col-12">
                <div className="float-end">
                  <Button size="sm"
                    onClick={() => loginToAccount(id!)}
                    className="btn btn-sm btn-primary"
                  >
                    Login to account
                  </Button>
                  {model.billingSubscriptionId &&
                    <a
                      target="_blank"
                      href={`https://dashboard.stripe.com/subscriptions/${model.billingSubscriptionId}`}
                      className="ml-10 btn btn-sm btn-neutral"
                    >
                      Manage subscription
                    </a>
                  }
                  <Button size="sm"
                  variant="danger"
                    onClick={() => setIsCancelModalShown(true)}
                    className="ml-10 btn btn-sm btn-danger"
                  >
                    Cancel account
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="bg-surface-secondary">
        <div className="container">
          <div className="row g-6">
            <div className="col-xl-8">
              <div className="vstack gap-6">
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmitBillingInformation}
                >
                  <div className="card">
                    <div className="card-header d-flex align-items-center">
                      <h5 className="mb-0">Billing information</h5>
                    </div>
                    <div className="card-body">
                      <div className="row g-5">
                        <div className="col-md-12">
                          <div className="">
                            <label className="form-label" htmlFor="notes">
                              Notes
                            </label>
                            <Form.Control
                              name={"notes"}
                              className="form-control"
                              value={model.notes!}
                              onChange={handleChange}
                              as="textarea"
                              rows={4}
                            />
                          </div>
                        </div>
                        {model && model.billingProvider === "Stripe" &&
                          <div className="col-md-12">
                            <div className="">
                              <label className="form-label" htmlFor="billingSubscriptionId">
                                Stripe subscription Id
                              </label>
                              <Form.Control
                                name={"billingSubscriptionId"}
                                className="form-control"
                                type="text"
                                value={model.billingSubscriptionId}
                                onChange={handleChange}
                              />
                            </div>
                          </div>}
                        <div className="col-md-12">
                          <div className="">
                            <label className="form-label" htmlFor="billingAdditionalGiftCardPrice">
                              Additional gift card price (need to be updated in Stripe)
                            </label>
                            <Form.Control
                              name={"billingAdditionalGiftCardPrice"}
                              className="form-control"
                              type="number"
                              value={model.billingAdditionalGiftCardPrice}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <Form.Check
                            id="chkBillingShouldChargeForAdditionalGiftCards"
                            name="billingShouldChargeForAdditionalGiftCards"
                            checked={model.billingShouldChargeForAdditionalGiftCards}
                            onChange={handleChange}
                            label="Should charge for additional gift cards?"
                          />
                        </div>
                        {model && model.billingShouldChargeForAdditionalGiftCards &&
                          <>
                            <div className="col-md-12">
                              <div className="">
                                <label className="form-label" htmlFor="billingNumberOfFreeGiftCards">
                                  Number of free gift cards per month
                                </label>
                                <Form.Control
                                  name={"billingNumberOfFreeGiftCards"}
                                  className="form-control"
                                  type="number"
                                  value={model.billingNumberOfFreeGiftCards}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                            </div>
                          </>}
                        <div className="col-md-12">
                          <Form.Check
                            id="isTest"
                            name="isTest"
                            checked={model.isTest}
                            onChange={handleChange}
                            label="Is testing account?"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                          <Form.Check
                            id="chkMarketingFeaturesEnabled"
                            name="marketingFeaturesEnabled"
                            checked={model.marketingFeaturesEnabled}
                            onChange={handleChange}
                            label="Is marketing section enabled?"
                          />
                        </div>
                    </div>

                    <div className="card-footer text-end py-4">
                      <button type="submit" className="btn btn-sm btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmitContactInformation}
                >
                  <div className="card">
                    <div className="card-header d-flex align-items-center">
                      <h5 className="mb-0">Contact information</h5>
                    </div>
                    <div className="card-body">
                      <div className="row g-5">
                        <div className="col-md-12">
                          <div className="">
                            <label className="form-label" htmlFor="companyName">
                              Company name
                            </label>
                            <Form.Control
                              name={"companyName"}
                              className="form-control"
                              type="text"
                              value={model.companyName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="">
                            <label className="form-label" htmlFor="name">
                              Contact name
                            </label>
                            <Form.Control
                              name={"name"}
                              className="form-control"
                              type="text"
                              value={model.name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="">
                            <label className="form-label" htmlFor="replyToEmailAddress">
                              Customer reply to email
                            </label>
                            <Form.Control
                              name={"replyToEmailAddress"}
                              className="form-control"
                              type="text"
                              value={model.replyToEmailAddress}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="">
                            <label className="form-label" htmlFor="emailAddress">
                              Contact email
                            </label>
                            <Form.Control
                              name={"emailAddress"}
                              className="form-control"
                              type="email"
                              value={model.emailAddress}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="">
                            <label className="form-label" htmlFor="phone">
                              Contact phone number
                            </label>
                            <Form.Control
                              name={"phone"}
                              className="form-control"
                              type="phone"
                              value={model.phone || ""}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="card-footer text-end py-4">
                      <button type="submit" className="btn btn-sm btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmitBusinessInformation}
                >
                  <div className="card">
                    <div className="card-header d-flex align-items-center">
                      <h5 className="mb-0">Business information</h5>
                    </div>
                    <div className="card-body">
                      <div className="row g-5">
                        <div className="col-md-12">
                          <label
                            className="form-label"
                            htmlFor="businessCategory"
                          >
                            Business Category
                          </label>
                          <Form.Select
                            name="businessCategory"
                            onChange={handleChange}
                            value={model.businessCategory}
                            required
                          >
                            <option value={""}>Choose...</option>
                            <option value={"Apparel"}>
                              Apparel &amp; Accessories
                            </option>
                            <option value={"Automotive"}>Automotive</option>
                            <option value={"Electronics"}>
                              Consumer Electronics
                            </option>
                            <option value={"Food"}>Food &amp; Drink</option>
                            <option value={"HealthBeauty"}>
                              Health &amp; Beauty
                            </option>
                            <option value={"Homeware"}>
                              Homeware &amp; Home Furnishings
                            </option>
                            <option value={"Office"}>Office</option>
                            <option value={"Other"}>Other</option>
                            <option value={"Specialty"}>Specialty</option>
                            <option value={"Sporting"}>Sporting Goods</option>
                          </Form.Select>
                        </div>
                        <div className="col-md-12">
                          <label className="form-label" htmlFor="businessType">
                            Business Type
                          </label>
                          <Form.Select
                            name="businessType"
                            onChange={handleChange}
                            value={model.businessType}
                            required
                          >
                            <option value={""}>Choose...</option>
                            <option value={"OnlineOnly"}>Online Only</option>
                            <option value={"OnlineAndPhysical"}>
                              Online + Physical Location(s)
                            </option>
                            <option value={"OnlineAndWholesale"}>
                              Online + Physical Location(s) + Wholesale
                            </option>
                            <option value={"OnlineAndWholesaleAndPhysical"}>
                              Online + Wholesale
                            </option>
                          </Form.Select>
                        </div>
                        <div className="col-md-12">
                          <label className="form-label" htmlFor="timezone">
                            Time Zone
                          </label>
                          <TimezoneSelect
                            value={timezone}
                            onChange={timezoneOnChange}
                          />
                        </div>
                        <div className="col-md-12">
                          <label className="form-label" htmlFor="isoCurrencyCode">
                            Currency
                          </label>

                          <Form.Select
                            name="isoCurrencyCode"
                            onChange={handleChange}
                            value={model.isoCurrencyCode}
                          >
                            <option value="AFN">Afghani (AFN)</option>
                            <option value="DZD">Algerian Dinar (DZD)</option>
                            <option value="ARS">Argentine Peso (ARS)</option>
                            <option value="AMD">Armenian Dram (AMD)</option>
                            <option value="AWG">Aruban Guilder (AWG)</option>
                            <option value="AUD">Australian Dollar (AUD)</option>
                            <option value="AZN">Azerbaijanian Manat (AZN)</option>
                            <option value="BSD">Bahamian Dollar (BSD)</option>
                            <option value="BHD">Bahraini Dinar (BHD)</option>
                            <option value="THB">Baht (THB)</option>
                            <option value="PAB">
                              Balboa US Dollar (PAB USD)
                            </option>
                            <option value="BBD">Barbados Dollar (BBD)</option>
                            <option value="BYR">Belarussian Ruble (BYR)</option>
                            <option value="BZD">Belize Dollar (BZD)</option>
                            <option value="BMD">Bermudian Dollar (BMD)</option>
                            <option value="VEF">Bolivar Fuerte (VEF)</option>
                            <option value="BOB">Boliviano Mvdol (BOB BOV)</option>
                            <option value="BRL">Brazilian Real (BRL)</option>
                            <option value="BND">Brunei Dollar (BND)</option>
                            <option value="BGN">Bulgarian Lev (BGN)</option>
                            <option value="BIF">Burundi Franc (BIF)</option>
                            <option value="XOF">CFA Franc BCEAO (XOF)</option>
                            <option value="XAF">CFA Franc BEAC (XAF)</option>
                            <option value="XPF">CFP Franc (XPF)</option>
                            <option value="CAD">Canadian Dollar (CAD)</option>
                            <option value="CVE">Cape Verde Escudo (CVE)</option>
                            <option value="KYD">
                              Cayman Islands Dollar (KYD)
                            </option>
                            <option value="GHS">Cedi (GHS)</option>
                            <option value="CLP CLF">
                              Chilean Peso (CLP CLF)
                            </option>
                            <option value="COP COU">
                              Colombian Peso (COP COU)
                            </option>
                            <option value="KMF">Comoro Franc (KMF)</option>
                            <option value="CDF">Congolese Franc (CDF)</option>
                            <option value="BAM">Convertible Marks (BAM)</option>
                            <option value="NIO">Cordoba Oro (NIO)</option>
                            <option value="CRC">Costa Rican Colon (CRC)</option>
                            <option value="HRK">Croatian Kuna (HRK)</option>
                            <option value="CUP CUC">Cuban Peso (CUP CUC)</option>
                            <option value="CZK">Czech Koruna (CZK)</option>
                            <option value="GMD">Dalasi (GMD)</option>
                            <option value="DKK">Danish Krone (DKK)</option>
                            <option value="MKD">Denar (MKD)</option>
                            <option value="DJF">Djibouti Franc (DJF)</option>
                            <option value="STD">Dobra (STD)</option>
                            <option value="DOP">Dominican Peso (DOP)</option>
                            <option value="VND">Dong (VND)</option>
                            <option value="XCD">
                              East Caribbean Dollar (XCD)
                            </option>
                            <option value="EGP">Egyptian Pound (EGP)</option>
                            <option value="SVC">
                              El Salvador Colon US Dollar (SVC USD)
                            </option>
                            <option value="ETB">Ethiopian Birr (ETB)</option>
                            <option value="EUR">Euro (EUR)</option>
                            <option value="FKP">
                              Falkland Islands Pound (FKP)
                            </option>
                            <option value="FJD">Fiji Dollar (FJD)</option>
                            <option value="HUF">Forint (HUF)</option>
                            <option value="GIP">Gibraltar Pound (GIP)</option>
                            <option value="HTG">
                              Gourde US Dollar (HTG USD)
                            </option>
                            <option value="GBP">Great British Pound Sterling (GBP)</option>

                            <option value="PYG">Guarani (PYG)</option>
                            <option value="GNF">Guinea Franc (GNF)</option>
                            <option value="GYD">Guyana Dollar (GYD)</option>
                            <option value="HKD">Hong Kong Dollar (HKD)</option>
                            <option value="UAH">Hryvnia (UAH)</option>
                            <option value="ISK">Iceland Krona (ISK)</option>
                            <option value="INR">Indian Rupee (INR)</option>
                            <option value="BTN">
                              Indian Rupee Ngultrum (INR BTN)
                            </option>
                            <option value="IRR">Iranian Rial (IRR)</option>
                            <option value="IQD">Iraqi Dinar (IQD)</option>
                            <option value="JMD">Jamaican Dollar (JMD)</option>
                            <option value="JOD">Jordanian Dinar (JOD)</option>
                            <option value="KES">Kenyan Shilling (KES)</option>
                            <option value="PGK">Kina (PGK)</option>
                            <option value="LAK">Kip (LAK)</option>
                            <option value="EEK">Kroon (EEK)</option>
                            <option value="KWD">Kuwaiti Dinar (KWD)</option>
                            <option value="MWK">Kwacha (MWK)</option>
                            <option value="AOA">Kwanza (AOA)</option>
                            <option value="MMK">Kyat (MMK)</option>
                            <option value="GEL">Lari (GEL)</option>
                            <option value="LVL">Latvian Lats (LVL)</option>
                            <option value="LBP">Lebanese Pound (LBP)</option>
                            <option value="ALL">Lek (ALL)</option>
                            <option value="HNL">Lempira (HNL)</option>
                            <option value="SLL">Leone (SLL)</option>
                            <option value="LRD">Liberian Dollar (LRD)</option>
                            <option value="LYD">Libyan Dinar (LYD)</option>
                            <option value="SZL">Lilangeni (SZL)</option>
                            <option value="LTL">Lithuanian Litas (LTL)</option>
                            <option value="MGA">Malagasy Ariary (MGA)</option>
                            <option value="MYR">Malaysian Ringgit (MYR)</option>
                            <option value="TMT">Manat (TMT)</option>
                            <option value="MUR">Mauritius Rupee (MUR)</option>
                            <option value="MZN">Metical (MZN)</option>
                            <option value="MXN">Mexican Peso (MXN MXV)</option>
                            <option value="MDL">Moldovan Leu (MDL)</option>
                            <option value="MAD">Moroccan Dirham (MAD)</option>
                            <option value="NGN">Naira (NGN)</option>
                            <option value="ERN">Nakfa (ERN)</option>
                            <option value="NPR">Nepalese Rupee (NPR)</option>
                            <option value="ANG">
                              Netherlands Antillian Guilder (ANG)
                            </option>
                            <option value="ILS">New Israeli Sheqel (ILS)</option>
                            <option value="RON">New Leu (RON)</option>
                            <option value="TWD">New Taiwan Dollar (TWD)</option>
                            <option value="NZD">New Zealand Dollar (NZD)</option>
                            <option value="KPW">North Korean Won (KPW)</option>
                            <option value="NOK">Norwegian Krone (NOK)</option>
                            <option value="PEN">Nuevo Sol (PEN)</option>
                            <option value="MRO">Ouguiya (MRO)</option>
                            <option value="TOP">Pa'anga (TOP)</option>
                            <option value="PKR">Pakistan Rupee (PKR)</option>
                            <option value="XPD">Palladium (XPD)</option>
                            <option value="MOP">Pataca (MOP)</option>
                            <option value="UYI">Peso Uruguayo (UYU UYI)</option>
                            <option value="PHP">Philippine Peso (PHP)</option>
                            <option value="XPT">Platinum (XPT)</option>
                            <option value="BWP">Pula (BWP)</option>
                            <option value="QAR">Qatari Rial (QAR)</option>
                            <option value="GTQ">Quetzal (GTQ)</option>
                            <option value="ZAR">Rand (ZAR)</option>
                            <option value="LSL">Rand Loti (ZAR LSL)</option>
                            <option value="NAD">
                              Rand Namibia Dollar (ZAR NAD)
                            </option>
                            <option value="OMR">Rial Omani (OMR)</option>
                            <option value="KHR">Riel (KHR)</option>
                            <option value="MVR">Rufiyaa (MVR)</option>
                            <option value="IDR">Rupiah (IDR)</option>
                            <option value="RUB">Russian Ruble (RUB)</option>
                            <option value="RWF">Rwanda Franc (RWF)</option>
                            <option value="XDR">SDR (XDR)</option>
                            <option value="SHP">Saint Helena Pound (SHP)</option>
                            <option value="SAR">Saudi Riyal (SAR)</option>
                            <option value="RSD">Serbian Dinar (RSD)</option>
                            <option value="SCR">Seychelles Rupee (SCR)</option>
                            <option value="SGD">Singapore Dollar (SGD)</option>
                            <option value="SBD">
                              Solomon Islands Dollar (SBD)
                            </option>
                            <option value="KGS">Som (KGS)</option>
                            <option value="SOS">Somali Shilling (SOS)</option>
                            <option value="TJS">Somoni (TJS)</option>
                            <option value="LKR">Sri Lanka Rupee (LKR)</option>
                            <option value="SDG">Sudanese Pound (SDG)</option>
                            <option value="SRD">Surinam Dollar (SRD)</option>
                            <option value="SEK">Swedish Krona (SEK)</option>
                            <option value="CHF">Swiss Franc (CHF)</option>
                            <option value="SYP">Syrian Pound (SYP)</option>
                            <option value="BDT">Taka (BDT)</option>
                            <option value="WST">Tala (WST)</option>
                            <option value="TZS">Tanzanian Shilling (TZS)</option>
                            <option value="KZT">Tenge (KZT)</option>
                            <option value="TTD">
                              Trinidad and Tobago Dollar (TTD)
                            </option>
                            <option value="MNT">Tugrik (MNT)</option>
                            <option value="TND">Tunisian Dinar (TND)</option>
                            <option value="TRY">Turkish Lira (TRY)</option>
                            <option value="AED">UAE Dirham (AED)</option>
                            <option value="XFU">UIC-Franc (XFU)</option>
                            <option selected={true} value="USD">
                              US Dollar (USD)
                            </option>
                            <option value="UGX">Uganda Shilling (UGX)</option>
                            <option value="UZS">Uzbekistan Sum (UZS)</option>
                            <option value="VUV">Vatu (VUV)</option>
                            <option value="KRW">Won (KRW)</option>
                            <option value="YER">Yemeni Rial (YER)</option>
                            <option value="JPY">Yen (JPY)</option>
                            <option value="CNY">Yuan Renminbi (CNY)</option>
                            <option value="ZMK">Zambian Kwacha (ZMK)</option>
                            <option value="ZWL">Zimbabwe Dollar (ZWL)</option>
                            <option value="PLN">Zloty (PLN)</option>
                          </Form.Select>
                        </div>

                        <div className="col-md-12">
                          <div className="">
                            <label className="form-label" htmlFor="website">
                              Website
                            </label>
                            <Form.Control
                              name={"website"}
                              className="form-control"
                              type="text"
                              value={model.website || ""}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="card-footer text-end py-4">
                      <button type="submit" className="btn btn-sm btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="vstack gap-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="mb-4">Account status</h5>
                    <div className="d-flex align-items-center"><span className="text-heading">
                      {model.accountStatus === "Active" ? (<Badge bg="success" text="light">
                        {model.accountStatus}
                      </Badge>) : (<Badge bg="dark" text="light">
                        {model.accountStatus}
                      </Badge>)}
                    </span></div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="mb-4">Billing plan</h5>
                    <div className="d-flex align-items-center">            
                    <span className="text-heading">
                      {model.billingPlan} {model.isTest && <Badge bg="secondary" text="dark">
                        Test
                      </Badge>}
                    </span></div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="mb-4">Account created</h5>
                    <div className="d-flex align-items-center">            
                    <span className="text-heading">
                    {dayjs(model.createdAt)
                .utc()
                .tz(authContext?.account?.ianaTimezone)
                .format("YYYY-MM-DD hh:mm a")}
                    </span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminCancelAccountModal
      show={isCancelModalShown}
      onHide={() => setIsCancelModalShown(false)}
      onCancelled={onAccountCancelled}
      />
    </React.Fragment>
  );
};

export default AdminAccountPage;
