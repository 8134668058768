import React, { useContext, useState, useEffect } from "react";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  ToggleButton,
  ButtonGroup,
} from "react-bootstrap";
import { BillingPlan, BillingApi, BillingStripeSubscription } from "../../apiclient";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AccountContext";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import { CrispChatContext } from "../../components/CrispChat";
import FreePlanDowngradeModal from "./FreePlanDowngradeModal";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import FaqComponent from "./FaqComponent";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig, shopifyAppBridge } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import AccountPlanFeaturesComponent from "./AccountPlanFeaturesComponent";
import AccountPlanOptionComponent from "./AccountPlanOptionComponent";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/relativeTime"));

const AccountPlanComponent = (props: any) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { width, height } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const hasUpgraded = searchParams.get("upgraded") ?? "";
  const ctaButtonText =
    props.stage === "onboarding" ? "Start free trial" : "Choose plan";
  const stage = props.stage ?? "";
  const [showUpgraded, setShowUpgraded] = useState(false);
  //const [showAnnual, setShowAnnual] = useState(true);
  const [billingType, setBillingType] = useState("annual");
  const [billingCurrency, setBillingCurrency] = useState("USD");
  const [billingPlanPrice, setBillingPlanPrice] = useState("49");
  const [billingPerGiftCardPrice, setBillingPerGiftCardPrice] = useState("0.50");
  const [model, setModel] = useState({} as BillingStripeSubscription);

  const generateOptions = [
    { name: "Bill monthly", value: "monthly" },
    { name: "Bill annually", value: "annually" },
  ];

  const [isDowngradeModalShown, setIsDowngradeModalShown] = useState(false);
  let billingApi = new BillingApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const {
    boot,
    shutdown,
    hardShutdown,
    update,
    hide,
    show,
    showMessages,
    showNewMessages,
    getVisitorId,
    startTour,
    trackEvent,
    showArticle,
  } = useIntercom();

  useEffect(() => {
    if (hasUpgraded === "true") {
      //navigate("/dashboard?upgraded=true");
      setShowUpgraded(true);
    }

    console.log("stage", stage, props.stage);

    if (authContext.account?.billingPlan?.includes("Annual")) {
      setBillingType("annually");
    } else {
      setBillingType("monthly");
    }

    if (authContext.account?.isoCurrencyCode?.toLowerCase() === "nzd") {
      setBillingCurrency("NZD");
      setBillingPlanPrice("79")
      setBillingPerGiftCardPrice("0.80");
    } else {
      setBillingCurrency("USD");
      setBillingPlanPrice("49")
      setBillingPerGiftCardPrice("0.50");
    }

    if (authContext.account?.billingProvider === "Stripe" && authContext.account.billingSubscriptionId) {
      fetchSubscriptionData();
    }

  }, []);

  const fetchSubscriptionData = () => {
    billingApi
      .billingStripeSubscriptionGet()
      .then((response) => {
        setModel(response.data);
      })
      .catch((error: any) => { });
  };


  function openChatForEnterpiseRequest() {
    showNewMessages("I'd like to request pricing for the Enterprise plan.");
  }

  const formatCentsToDollars = (cents: number) => {
    const dollars: number = cents / 100;
    return dollars.toFixed(2);
  };


  const showDowngradeModal = (event: any) => {
    setIsDowngradeModalShown(true);
  };

  const getChangePlanUrl = (newPlan: BillingPlan) => {
    if (authContext.account?.billingProvider === "Shopify") {
      return `/Shopify/ChangePlan?plan=${newPlan}`;
    } else return `/Billing/ChangePlan?plan=${newPlan}`;
  };

  const iframeTarget = () => {
    try {
      return window.self !== window.top ? "_blank" : "_self";
    } catch (e) {
      return "_blank";
    }
  };

  const handleAnnualChange = (event: any) => {
    //setShowAnnual(!showAnnual);
  };

  function daysBetweenDates(date1: Date, date2: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
    const diffTime = Math.abs(date2.getTime() - date1.getTime()); // difference in milliseconds
    return Math.ceil(diffTime / oneDay);
  }

  return (
    <React.Fragment>
      <div className=" pt-10 pb-24">
        <div className="container max-w-screen-xl">
          {showUpgraded &&
            (authContext.account?.billingPlan === "Free" ? (
              <>
                <div className="alert alert-success mb-5" role="alert">
                  <div className="d-flex align-items-center">
                    <div className="w-8 text-lg">
                      <i className="bi bi-check-circle-fill"></i>
                    </div>
                    <span className="font-bold">Success</span>
                  </div>
                  <div className="ps-8">
                    <p className="text-sm">
                      You have downgraded your account to the Free plan. Many
                      features are no longer available.
                    </p>
                    <ul className="m-0 ps-3 text-sm"></ul>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="alert alert-success mb-5" role="alert">
                  <div className="d-flex align-items-center">
                    <div className="w-8 text-lg">
                      <i className="bi bi-check-circle-fill"></i>
                    </div>
                    <span className="font-bold">Success</span>
                  </div>
                  <div className="ps-8">
                    <p className="text-sm">
                      You are now on the {authContext.account?.billingPlan}{" "}
                      plan.
                    </p>
                    <ul className="m-0 ps-3 text-sm"></ul>
                  </div>
                </div>
                <Confetti
                  width={width}
                  height={height}
                  recycle={false}
                  numberOfPieces={500}
                />
              </>
            ))}

          {/* <!-- Content --> */}
          <div className="row justify-content-center">
            <div className="col-lg-12">
              {(authContext.account?.billingProvider === "Stripe" && authContext.account.billingSubscriptionId) &&
                <>
                  <h6 className="lh-tight ls-tight display-6 mb-7">Billing</h6>
                  <div className="card border-0 shadow">
                    <div className="card-body py-4">
                      <div className="d-flex align-items-center justify-content-between mb-5">
                        <div className="flex-1">
                          <h6 className="text-2xl font-semibold mb-1">{authContext.account.billingPlan} plan </h6> <span className="badge text-uppercase bg-opacity-30 bg-info text-dark">{model.status}</span>
                          {model.description &&
                            <p className="text-sm text-muted">{model.description}</p>
                          }
                        </div>
                        <div className="ms-auto">
                          <div className="d-block h6 font-semibold mb-1">
                            Billing period: {dayjs(model.currentPeriodStart)
                              .utc()
                              .tz(
                                authContext?.account?.ianaTimezone
                              )
                              .format("YYYY-MM-DD")} - {dayjs(model.currentPeriodEnd)
                                .utc()
                                .tz(
                                  authContext?.account?.ianaTimezone
                                )
                                .format("YYYY-MM-DD")}
                          </div>
                        </div>
                      </div>
                      <div className="list-group list-group-flush">
                        {model &&
                          model.items?.map((li, i) =>
                            <div key={"planItem-" + i} className="list-group-item d-flex">
                              <div>
                                <a href="" className="text-sm text-muted"></a>
                              </div>
                              <div className="flex-fill">
                                <div className="d-block h5 font-semibold mb-1">
                                  {li.description} @ ${formatCentsToDollars(li.amountInCents!)}
                                </div>
                                {/* <p className="text-sm text-muted">
                                {li.interval}
                              </p> */}
                              </div>
                              <div className="ms-auto text-end align-self-center">
                                <div className=" d-flex align-items-center gap-4">
                                  <div>
                                    <span className="font-semibold text-base text-heading">${formatCentsToDollars(li.totalAmountInCents!)} {model.currency?.toUpperCase()}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                      </div>
                      <hr className="my-4" />
                      <div className="d-flex align-items-center justify-content-between">
                        {/* <a href="#" className="text-muted text-danger-hover text-sm font-semibold">Cancel subscription</a> */}
                        <a href="/Billing/CustomerPortal" className="btn btn-sm btn-primary float-end">View invoices and manage subscription</a>
                      </div>
                    </div>
                  </div></>}
              {authContext.account?.billingPlan != "Enterprise" &&
                <div className="row justify-content-center mt-10">
                  <div className="col-lg-12">
                    <div className="row g-10">
                      <AccountPlanOptionComponent
                        planName="Basic"
                        plan={BillingPlan.Base}
                        planPrice="49"
                        isAnnual={false}
                        numCards={50}
                        addtionalCardPrice="0.50"
                        numLocations={3}
                      />
                      <AccountPlanOptionComponent
                        planName="Pro"
                        plan={BillingPlan.Pro}
                        planPrice="99"
                        isAnnual={false}
                        numCards={200}
                        addtionalCardPrice="0.40"
                        numLocations={9}
                        mostPopular={false}
                      />
                      <AccountPlanOptionComponent
                        planName="Plus"
                        plan={BillingPlan.Plus}
                        planPrice="199"
                        isAnnual={false}
                        numCards={500}
                        addtionalCardPrice="0.20"
                        numLocations={20}
                      />
                    </div>
                  </div>
                </div>}
              <FaqComponent />
            </div>
          </div>
        </div>
      </div>
      <div className=" pt-10 pb-24">
        <div className="container max-w-screen-xl">
          {showUpgraded &&
            (authContext.account?.billingPlan === "Free" ? (
              <>
                <div className="alert alert-success mb-5" role="alert">
                  <div className="d-flex align-items-center">
                    <div className="w-8 text-lg">
                      <i className="bi bi-check-circle-fill"></i>
                    </div>
                    <span className="font-bold">Success</span>
                  </div>
                  <div className="ps-8">
                    <p className="text-sm">
                      You have downgraded your account to the Free plan. Many
                      features are no longer available.
                    </p>
                    <ul className="m-0 ps-3 text-sm"></ul>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="alert alert-success" role="alert">
                  <div className="d-flex align-items-center">
                    <div className="w-8 text-lg">
                      <i className="bi bi-check-circle-fill"></i>
                    </div>
                    <span className="font-bold">Success</span>
                  </div>
                  <div className="ps-8">
                    <p className="text-sm">
                      You are now on a paid plan. All features have now been
                      unlocked!
                    </p>
                    <ul className="m-0 ps-3 text-sm"></ul>
                  </div>
                </div>
                <Confetti
                  width={width}
                  height={height}
                  recycle={false}
                  numberOfPieces={500}
                />
              </>
            ))}
        </div>
      </div>
      <FreePlanDowngradeModal
        show={isDowngradeModalShown}
        onHide={() => setIsDowngradeModalShown(false)}
      />
    </React.Fragment>
  );
};

export default AccountPlanComponent;
