import React, { useContext, useState, useEffect } from "react";
import { BillingPlan } from "../../apiclient";
const AccountPlanFeaturesComponent = (props: any) => {
  return (
    <React.Fragment>
      {(props.plan === BillingPlan.Base || props.plan === BillingPlan.BaseAnnual) && (
      <ul className="list-unstyled mb-7">
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>Omnichannel gift card program</p>
        </li>
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>Custom branding and emails</p>
        </li>
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>Analytics and liability reporting</p>
        </li>
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>Free migration import</p>
        </li>
      </ul>)}
      {(props.plan === BillingPlan.Pro || props.plan === BillingPlan.ProAnnual) && (
      <ul className="list-unstyled mb-7">
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>All Basic plan features</p>
        </li>
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>Unlimited integrations (e.g. Klaviyo)</p>
        </li>
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>API Access</p>
        </li>
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>Marketing automations</p>
        </li>
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>Marketing campaigns</p>
        </li>
      </ul>)}
      {(props.plan === BillingPlan.Plus || props.plan === BillingPlan.PlusAnnual) && (
      <ul className="list-unstyled mb-7">
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>All Pro plan features</p>
        </li>
        
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>Premium onboarding</p>
        </li>
        <li className="py-2 d-flex align-items-center">
          <div className="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
            <i className="bi bi-check"></i>
          </div>
          <p>Priority support</p>
        </li>
      </ul>)}
    </React.Fragment>
  );
};

export default AccountPlanFeaturesComponent;
