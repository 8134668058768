import React, { lazy, Component, Suspense  } from 'react';
import { Route } from 'react-router-dom';
import './custom.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {Provider, Loading} from '@shopify/app-bridge-react';
import { Buffer } from 'buffer';
import { shopifyAppBridge } from './config';

import { LoginLayout } from './layouts/LoginLayout';
import { Layout } from './layouts/Layout';
import { BlankLayout } from './layouts/BlankLayout';
import { NoSidebarLayout } from './layouts/NoSidebarLayout';
import { NoBarLayout } from './layouts/NoBarLayout';
import { Spinner } from "react-bootstrap";
import HomeOnboardingPage from './pages/home/HomeOnboardingPage';
import PublicCheckBalancePage from './pages/public_customer/PublicCheckBalancePage';
import ShopifyRefundPage from './pages/refund/ShopifyRefundPage';
import VendPaymentTypeRefundPage from './pages/refund/VendPaymentTypeRefundPage';
import RefundExplainerPage from './pages/refund/RefundExplainerPage';

import UsersPage from './pages/account/UsersPage';
import SignInPage from './pages/account/SignInPage';
import ForgotPasswordPage from './pages/account/ForgotPasswordPage';
import ResetPasswordPage from './pages/account/ResetPasswordPage';
import AdminLoginPage from './pages/admin/AdminLoginPage';
import ChooseAccountPage from './pages/admin/ChooseAccountPage';
import CompleteProfilePage from './pages/onboarding/CompleteProfilePage';
import OnboardingChoosePlanPage from './pages/onboarding/OnboardingChoosePlanPage';
import AccountNotificationsPage from './pages/account/AccountNotificationsPage';
import CreateAccountPage from './pages/account/CreateAccountPage';
import OnboardingConnectStore from './pages/onboarding/OnboardingConnectStore';
import WooCommerceSetup from './pages/onboarding/WooCommerceSetup';
import RedirectToShopifyAuthPage from './pages/shopify/RedirectToShopifyAuthPage';
import RedirectToExternalPage from './pages/shopify/RedirectToExternalPage';
import ApiKeysPage from './pages/integrations/ApiKeysPage';
import FetchData from './components/FetchData';
import Counter from './components/Counter';
import TestPage from './components/TestPage';
import AccountPage from './pages/account/AccountPage';
import AccountPlanPage from './pages/account/AccountPlanPage';
import IntegrationsPage from './pages/integrations/IntegrationsPage';
import NotFoundPage from './pages/error/NotFoundPage';
import GiftCardsListPage from './pages/giftcards/GiftCardsListPage';
import GiftCardSettingsPage from './pages/giftcards/GiftCardSettingsPage';
import GiftCardNewPage from './pages/giftcards/GiftCardNewPage';
import GiftCardDetailPage from './pages/giftcards/GiftCardDetailPage';
import AssetsListPage from './pages/assets/AssetsListPage';
import EmailsListPage from './pages/emails/EmailsListPage';
import EmailPage from './pages/emails/EmailPage';
import CheckBalancePage from './pages/assets/CheckBalancePage';
import GiftToFriendSettingsPage from './pages/assets/GiftToFriendSettingsPage';
import WalletDesignPage from './pages/assets/WalletDesignPage';
import PublicGiftToFriendPage from './pages/public_customer/PublicGiftToFriendPage';
import WorkflowDesignPage from './pages/workflows/WorkflowDesignPage';
import MagicLinkConfirm from './pages/account/MagicLinkConfirm';
import PublicGiftToFriendAdvancedPage from './pages/public_customer/PublicGiftToFriendAdvancedPage';
import PublicRegisterInstoreGiftCardPage from './pages/public_customer/PublicRegisterInstoreGiftCardPage';
import SenderDomainsPage from './pages/emails/SenderDomainsPage';
import LSKGiftCardPage from './pages/pos/LSKGiftCardPage';
import GiftCardReportsPage from './pages/reports/GiftCardReportsPage';
import RevenueReportsPage from './pages/reports/RevenueReportsPage';
import CustomersListPage from './pages/customers/CustomersListPage';
import CustomerDetailPage from './pages/customers/CustomerDetailPage';
import EcwidGiftCardPage from './pages/pos/EcwidGiftCardPage';
import WorkflowsListPage from './pages/workflows/WorkflowsListPage';
import SalesChannelsPage from './pages/account/SalesChannelsPage';
import GiftCardBatchNewPage from './pages/giftCardBatches/GiftCardBatchNewPage';
import GiftCardBatchReportsPage from './pages/giftCardBatches/GiftCardBatchReportsPage';
import GiftCardBatchListPage from './pages/giftCardBatches/GiftCardBatchListPage';
import ErrorPage from './pages/error/ErrorPage';
import StorefrontSettingsPage from './pages/storefront/StorefrontSettingsPage';
import StaffCheckBalancePage from './pages/staffbalancechecker/StaffCheckBalancePage';
import RSeriesGiftCardPage from './pages/pos/RSeriesGiftCardPage';
import UnsubscribePage from './pages/emails/UnsubscribePage';
import AdvancedCustomizationPage from './pages/assets/AdvancedCustomizationPage';
import AutomationEditPage from './pages/automations/AutomationEditPage';
import AutomationReportsPage from './pages/automations/AutomationReportsPage';
import AutomationListPage from './pages/automations/AutomationListPage';
import AutomationEmailPage from './pages/automations/AutomationEmailPage';
import Login2faPage from './pages/account/Login2faPage';
import RedeemAppSettingsPage from './pages/redeemApp/RedeemAppSettingsPage';
import AdminAccountsListPage from './pages/admin/AdminAccountsListPage';
import { AdminLayout } from './layouts/AdminLayout';
import AdminAccountPage from './pages/admin/AdminAccountPage';
import AdminLogin2faPage from './pages/admin/AdminLogin2faPage';
import PublicAddToCartPage from './pages/public_customer/PublicAddToCartPage';
import CampaignEditPage from './pages/campaigns/CampaignEditPage';
import CampaignListPage from './pages/campaigns/CampaignListPage';
import CampaignReportsPage from './pages/campaigns/CampaignReportsPage';
import CampaignEmailPage from './pages/campaigns/CampaignEmailPage';

const routesConfig = [
    {
        element: <BlankLayout />,
        children: [
            {
                path: "/shopify/RedirectToShopifyAuthPage",
                element: <RedirectToShopifyAuthPage />
            },
            {
                path: "/shopify/RedirectToExternalPage",
                element: <RedirectToExternalPage />
            },
            {
                path: "/pc/check-balance/:id",
                element: <PublicCheckBalancePage />,
            },
            {
                path: "/pc/staff-check-balance/:id",
                element: <StaffCheckBalancePage />,
            },
            {
                path: "/pc/register-instore-giftcard/:id/:platformId",
                element: <PublicRegisterInstoreGiftCardPage />,
            },
            {
                path: "/public/gift-to-friend/:id",
                element: <PublicGiftToFriendAdvancedPage />,
            },
            {
                path: "/public/add-to-cart/:id",
                element: <PublicAddToCartPage />,
            },
            {
                path: "/public/gift-to-friend-new/:id",
                element: <PublicGiftToFriendAdvancedPage />,
            },
            {
                path: "/public/gift-to-friend-legacy/:id",
                element: <PublicGiftToFriendPage />,
            },
            {
                path: "/shopify/refund",
                element: <ShopifyRefundPage />,
            },
            {
                path: "/vend/refund/:accountPlatformId",
                element: <VendPaymentTypeRefundPage />,
            },
            {
                path: "/pos/lskgiftcard/:id",
                element: <LSKGiftCardPage />,
            },
            {
                path: "/pos/rseries/:id",
                element: <RSeriesGiftCardPage />,
            },
            {
                path: "/ecom/ecwid/payment/:id",
                element: <EcwidGiftCardPage />,
            },
            {
                path: "/error/unhandled",
                element: <ErrorPage />,
            },
            {
                path: "/pc/email-unsubscribe/:accountId",
                element: <UnsubscribePage />,
            },
        ],
    },
    {
        element: <NoBarLayout />,
        children: [
            {
                path: "/assets/check-balance-page",
                element: <CheckBalancePage />,
            },
            {
                path: "/assets/gift-to-friend-page",
                element: <GiftToFriendSettingsPage />,
            },
            {
                path: "/assets/wallet-design",
                element: <WalletDesignPage />,
            },
            {
                path: "/notifications/email-edit/:notificationType",
                element: <EmailPage />,
            },
            {
                path: "/automations/design-email/:id",
                element: <AutomationEmailPage />,
            },
            {
                path: "/campaigns/design-email/:id",
                element: <CampaignEmailPage />,
            },
            {
                path: "/workflows/design/:id",
                element: <WorkflowDesignPage />,
            },
        ],
    },
    {
        element: <NoSidebarLayout />,
        children: [
           
            {
                path: "/onboarding/complete-profile",
                element: <CompleteProfilePage />,
            },
            {
                path: "/onboarding/woocommerce-setup",
                element: <WooCommerceSetup />,
            },
            {
                path: "/onboarding/connect-store",
                element: <OnboardingConnectStore />,
            },
            {
                path: "/onboarding/selectplan",
                element: <OnboardingChoosePlanPage />,
            },
        ],
    },
    {
        element: <LoginLayout />,
        children: [
            {
                path: "/account/login",
                element: <SignInPage />,
            },
            {
                path: "/account/createaccount",
                element: <CreateAccountPage />,
            },
            {
                path: "/account/signup",
                element: <CreateAccountPage />,
            },
            {
                path: "/admin/login",
                element: <AdminLoginPage />,
            },
            {
                path: "/admin/login/:accountId",
                element: <AdminLoginPage />,
            },
            {
                path: "/admin/login2fa",
                element: <AdminLogin2faPage />,
            },
            {
                path: "/account/chooseaccount",
                element: <ChooseAccountPage />,
            },
            {
                path: "/account/magiclinkconfirm",
                element: <MagicLinkConfirm />,
            },
            {
                path: "/account/forgotpassword",
                element: <ForgotPasswordPage />,
            },
            {
                path: "/account/resetpassword",
                element: <ResetPasswordPage />,
            },
            {
                path: "/account/login2fa",
                element: <Login2faPage />,
            },
        ],
    },
    {
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <HomeOnboardingPage />,
            },
            {
                path: "/dashboard",
                element: <HomeOnboardingPage />,
            },
            {
                path: "/gift-cards/new",
                element: <GiftCardNewPage />,
            },
            {
                path: "/gift-cards/:id",
                element: <GiftCardDetailPage />,
            },
            {
                path: "/gift-cards/settings",
                element: <GiftCardSettingsPage />,
            },
            {
                path: "/gift-cards",
                element: <GiftCardsListPage />,
            },
            {
                path: "/workflows",
                element: <WorkflowsListPage />,
            },
            {
                path: "/refunds",
                element: <RefundExplainerPage />,
            },
            {
                path: "/assets",
                element: <AssetsListPage />,
            },
            {
                path: "/emails/senderdomains",
                element: <SenderDomainsPage />,
            },
            {
                path: "/emails",
                element: <EmailsListPage />,
            },
            {
                path: "/testpage",
                element: <TestPage />,
            },
            {
                path: "/account/notifications",
                element: <AccountNotificationsPage />,
            },
            {
                path: "/account/users",
                element: <UsersPage />,
            },
            {
                path: "/account/sales-channels",
                element: <SalesChannelsPage />,
            },
            {
                path: "/billingplan",
                element: <AccountPlanPage />,
            },
            {
                path: "/account",
                element: <AccountPage />,
            },
            {
                path: "/manage-integrations",
                element: <IntegrationsPage />,
            },
            {
                path: "/manage-api-keys",
                element: <IntegrationsPage />,
            }, 
            {
                path: "/reporting",
                element: <GiftCardReportsPage />
            },
            {
                path: "/customers/detail/:id",
                element: <CustomerDetailPage />,
            },
            {
                path: "/customers",
                element: <CustomersListPage />,
            },
            {
                path: "/bulk-gift-cards/new",
                element: <GiftCardBatchNewPage />,
            },
            {
                path: "/bulk-gift-cards/:id",
                element: <GiftCardBatchReportsPage />,
            },
            {
                path: "/bulk-gift-cards",
                element: <GiftCardBatchListPage />,
            },
            {
                path: "/account/storefront-settings",
                element: <StorefrontSettingsPage />,
            },
            {
                path: "/advanced-customization",
                element: <AdvancedCustomizationPage />,
            },
            {
                path: "/automations/edit/:id",
                element: <AutomationEditPage />,
            },
            {
                path: "/automations/reports/:id",
                element: <AutomationReportsPage />,
            },
            {
                path: "/automations",
                element: <AutomationListPage />,
            },
            {
                path: "/campaigns/edit/:id",
                element: <CampaignEditPage />,
            },
            {
                path: "/campaigns/reports/:id",
                element: <CampaignReportsPage />,
            },
            {
                path: "/campaigns",
                element: <CampaignListPage />,
            },
            {
                path: "/account/redeem-app",
                element: <RedeemAppSettingsPage />,
            },
            {
                path: "/reporting/revenue/:reportType/:id",
                element: <RevenueReportsPage />
            },
           
        ],
    },
    {
        element: <AdminLayout />,
        children: [
            {
                path: "/admin/list",
                element: <AdminAccountsListPage />,
            },
            {
                path: "/admin/account/detail/:id",
                element: <AdminAccountPage />,
            }
        ],
    },
    {
        path: "*",
        element: <NotFoundPage />,
    },
];

const router = createBrowserRouter(routesConfig);

const App = () => {
    var shopifyEmbedHostParam = (new URLSearchParams(window.location.search).get("host") || shopifyAppBridge.host);
    if(shopifyEmbedHostParam){

        shopifyAppBridge.host = shopifyEmbedHostParam;
        
        var shopifyAppBridgeConfig = {
            apiKey: "14c942f84c3721259a38ad0ac0daba47",
            host: shopifyEmbedHostParam || "",
            forceRedirect: true
        };
        return <>
           <Provider config={shopifyAppBridgeConfig}>
               <RouterProvider router={router} />
           </Provider>
       </>;
    }
    else {
        return <><RouterProvider router={router} /></>;
    }
};


export default App;